// src/TabRefresh.js
import { useEffect, useState } from 'react';

const TabRefresh = () => {
  const [hiddenTime, setHiddenTime] = useState(0);
  const [isTabHidden, setIsTabHidden] = useState(false);
  const refreshThreshold = 1800000; // 30 minutes threshold

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.hidden) {
        setHiddenTime(Date.now());
        setIsTabHidden(true);
      } else {
        const timeElapsed = Date.now() - hiddenTime;
        if (isTabHidden && timeElapsed >= refreshThreshold) {
          window.location.reload();
        }
        setIsTabHidden(false);
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [hiddenTime, isTabHidden]);

  return null; // No UI component
};

export default TabRefresh;
