// Importing necessary libraries and components
import React, { useEffect, useState, useLayoutEffect } from "react";
import Table2 from "./Table2";
import { Icon } from "@anchor/react-components";
import "../App.css";
import { TableContext } from "../context";
import { useContext } from "react";
import { toast } from "react-toastify";
// ViewChanges component
export default function ViewChangeData(props) {
  // State for the table data
  const [table7, setTable7] = useState([]);
  // Context for the graph data
  const { graphData, setGraphData, fetchUserDetails,fetchWithTimeout } =
    useContext(TableContext);
    const [testApplicationName, setTestApplicationName] = useState("");
  // Effect hook for fetching data when the component mounts or when graphData or table1.length changes
  useLayoutEffect(() => {
    if(graphData?.displayName!==undefined)
      {
        // getData();

      }
  }, [graphData, table7.length]);
  

  const handleapplicationNameChange = (e) => {
    e.preventDefault();
    setTestApplicationName(e.target.value);
  }

  const handleTestApplicationSearch = async (e) => {
    e.preventDefault();
    if (testApplicationName === "") {
      toast.error("Please enter a valid application name");
      return;}
    else {
        try {
            // Fetch data from the server
            await fetchWithTimeout(
              `${process.env.REACT_APP_BACKEND_URI}/getchangedata?applicationName=${encodeURIComponent(testApplicationName)}`,
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
              }
            )
              .then((res) => res.json())
              .then((response) => {
                const data = response.data;
                // Set the fetched data to the state
                setTable7(data);
                console.log(table7)
                // Set progress to 70
              });
            // Set progress to 100
          } catch (error) {
            if(error.message==="Timeout"){
              toast.error("Request Timed Out", {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
            }
            // Handle error
          }
    
    }
    }
  // Function for fetching data from the server
  
  // Rendering the ViewChanges component
  return (
    <div>
        <div className="container1" 
        style={{
            marginLeft: "35%",
            marginTop: "5%",
        }}
        >
            <form onSubmit={handleTestApplicationSearch} style={{ marginTop: "30px" }}>
                <label htmlFor="application-name">Application Name:  </label>
                <input
                    className="search-input"
                    type="text"
                    name="applicationName"
                    id="application-name"
                    onChange={handleapplicationNameChange}
                    value={testApplicationName}
                    autoComplete="off"
                />
                <input
                    type="submit"
                    style={{ backgroundColor: "#42B0D5" }}
                    value="Search"
                    // ref={inputRef}
                ></input>
            </form>
        </div>
        <div style={{marginLeft:'10%', marginRight:'10%', marginTop:'3%'}}>
        {table7?.length > 0 && (
            <table id="data-table" className="custom-table">
                <thead>
                <tr>
                    <th style={{ border: "2px solid #87CEEB" }}> Application</th>
                    <th style={{ border: "2px solid #87CEEB" }}>Application</th>
                    <th style={{ border: "2px solid #87CEEB" }}>Application</th>
                    <th style={{ border: "2px solid #87CEEB" }}>Application</th>
                    <th style={{ border: "2px solid #87CEEB" }}>Application</th>
                    <th style={{ border: "2px solid #87CEEB" }}>Application</th>
                    <th style={{ border: "2px solid #87CEEB" }}>Application</th>
                    <th style={{ border: "2px solid #87CEEB" }}> </th>
                </tr>
                </thead>
                <tbody>
                
                {table7.map((value, key) => {
                    return (
                    <tr
                        key={key}
                        id={key}
                    >
                        <td style={{ border: "2px solid #87CEEB" }}>
                        {value[0]}
                        <span
                            style={{
                            marginLeft: "10px",
                            position: "relative",
                            top: "6px",
                            }}
                        >
                            <Icon style={{}} name="info-circle" size="24" />
                        </span>
                        </td>
                        <td style={{ border: "2px solid #87CEEB" }}>{value[1]}</td>
                        <td style={{ border: "2px solid #87CEEB" }}>{value[2]}</td>
                        <td style={{ border: "2px solid #87CEEB" }}>{value[3]}</td>
                        <td style={{ border: "2px solid #87CEEB" }}>{value[4]}</td>
                        <td style={{ border: "2px solid #87CEEB" }}>{value[5]}</td>
                        <td style={{ border: "2px solid #87CEEB" }}>{value[6]}</td>

                        <td
                        style={{
                            border: "2px solid #87CEEB",
                            textAlign: "center",
                            // width: "125px",
                        }}
                        >
                        </td>
                    </tr>
                    );
                })}
                </tbody>
            </table>)}
        </div>

    </div>
  );
}
