import { ToastContainer, toast } from "react-toastify";
 
/**
 * Handles the display logic for subscription buttons based on the subscription status.
 *
 * @param {string} applicationName - The name of the application.
 * @param {Object} graphData - The graph data containing user information.
 * @param {string} graphData.mail - The email of the user.
 * @param {string} relationshipCategorySelected - The selected relationship category.
 * @param {Function} setShowSubscribe - Function to set the visibility of the subscribe button.
 * @param {Function} setShowUnsubscribe - Function to set the visibility of the unsubscribe button.
 * @returns {Promise<void>} - A promise that resolves when the subscription status is fetched and handled.
 */
export const handleShowSubscribe = async (applicationName, graphData, relationshipCategorySelected, setShowSubscribe, setShowUnsubscribe) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_BACKEND_URI}/checkSubscriptionStatus`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify({ app_name: applicationName, email: graphData?.mail, relationship_category: relationshipCategorySelected, }),
    });
    if (response.ok) {
      const subscriptionResult = await response.json();
      console.log(subscriptionResult);
      const subscriptionStatus = subscriptionResult.subscription_status;
      if (subscriptionStatus === "Subscribed") {
        setShowSubscribe(false);
        setShowUnsubscribe(true);
      } else if (subscriptionStatus === "Not Subscribed") {
        setShowSubscribe(true);
        setShowUnsubscribe(false);
      }
    }
  } catch (error) {
    console.error("Failed to fetch subscription status:", error);
  }
};
 
/**
 * Handles the subscription process for a given application.
 *
 * @param {string} applicationName - The name of the application to subscribe to.
 * @param {Object} graphData - The data object containing user information.
 * @param {string} graphData.mail - The email address of the user.
 * @returns {Promise<void>} - A promise that resolves when the subscription process is complete.
 *
 * @throws {Error} - Throws an error if the subscription process fails.
 */
export const handleSubscribe = async (applicationName, graphData) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_BACKEND_URI}/subscribe`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify({ app_name: applicationName, email: graphData?.mail, }),
    });
    const result = await response.json();
    if (result === "Subscription Successful") {
      toast.success(`${applicationName} - Subscribed Successfully`);
    }
  } catch (error) {
    toast.error("Subscription failed");
  }
 
};
 
/**
 * Handles the unsubscription process for a given application.
 *
 * @param {string} applicationName - The name of the application to unsubscribe from.
 * @param {Object} graphData - The graph data containing user information.
 * @param {string} graphData.mail - The email of the user to unsubscribe.
 * @returns {Promise<void>} - A promise that resolves when the unsubscription process is complete.
 *
 * @throws {Error} - Throws an error if the unsubscription process fails.
 */
export const handleUnsubscribe = async (applicationName, graphData) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_BACKEND_URI}/unsubscribe`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify({ app_name: applicationName, email: graphData?.mail, }),
    });
    const result = await response.json();
    if (result === "Unsubscription Successful") {
      toast.success(`${applicationName} - Unsubscribed Successfully`);
    }
  } catch (error) {
    toast.error("Unsubscription failed");
  }
};
 
 