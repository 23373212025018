import React from "react";
import Maintainance from "./Maintainance";
const Footer = () => {
  return (
    <>
    
    <footer style={footerStyle}>  
      <div style={logoStyle}>
        {" "}
        {/* Logo at top left corner */}
        <img src="./images/Maersk_Logo_RGB_Neg_Txt.svg" alt="Logo" height="100" />
      </div>
      <div style={linksContainerStyle}>
        {" "}
        {/* Links at the center */}
        <a
          style={{ margin: "20px", textDecoration: "none", color: "white" }}
          target="_blank"
          href="https://maersk-tools.atlassian.net/wiki/spaces/TechCHM/pages/182861791529/CMDB+Portal+Guide+and+Documentation"
        >
          Documentation
        </a>
        <a
          style={{ margin: "20px", textDecoration: "none", color: "white" }}
          target="_blank"
          href="https://maersk-tools.atlassian.net/wiki/spaces/TechCHM/pages/182846785609/Consuming+CMDB+portal+APIs+using+stargate"
        >
          API
        </a>
        <a type="email" style={{ margin: "20px", textDecoration: "none", color: "white" }} href="mailto:techopstooling@maersk.com">
          Contact Us
        </a>
      </div>
      <div style={labelStyle}>
        {" "}
        {/* Text label at bottom right */}
        Designed and developed by TechOps Tooling
      </div>
    </footer>
    </>
  );
};

// Styles
const footerStyle = {
  backgroundColor: "#00243D",
  padding: "20px",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  position: "fixed",
  color: "white",
  width: "100%",
  position: "fixed",
  height: "7vh",
  bottom: 0,
  zindex: 10,
  width: "100%",
  //   marginTop: '100px',
};
const logoStyle = {
  marginRight: "20px",
};
const linksContainerStyle = {
  display: "flex",
  justifyContent: "center",
  float: "center",
  textAlign: "center",
  color: "white",
  width: "100%",
  //   textDecoration: 'none',
};
const labelStyle = {
  marginLeft: "auto",
  width: "600px",
};
export default Footer;
