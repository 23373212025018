import React, { useContext, useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { TableContext } from "../context";
import { FaCheckCircle } from "react-icons/fa";
import { Icon } from "@anchor/react-components/dist/lib/components";
import { toast } from "react-toastify";

function Row(props) {
  const {
    row,
    isciowner,
  } = props;
  const [showConfirmation, setshowConfirmation] = useState(false);
  const {
    fetchWithTimeout,
    applicationName,
    graphData,
  } = useContext(TableContext);
  const handleDeleteClick = async () => {
    console.log("row", row);
    setshowConfirmation(false);
    const repository_name = row.repository_name;
    console.log("repository_name", repository_name);
    try {
      const response = await fetchWithTimeout(
        `${process.env.REACT_APP_BACKEND_URI}/deleterepomapping`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify({
            repositoryName: repository_name,
            applicationName: applicationName,
            user_name: graphData?.displayName,
 

          }),
        }
      );
      const data = await response.json();
      if(response.ok)
      {
       toast.success(
        "Deleted Successfully. Kindly search again to see updated changes",
         {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
         }
       ); 
      } else {
        console.error("Error deleting the mapped repository:", data.error);
        toast.error(
          "Error deleting the mapped repository. Please try again later.",
          {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          }
        );
      }     
    } catch (error) {
      console.error("Request failed:", error);
      toast.error(
        "Error deleting the repository. Please try again later.",
        {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        }
      );
    }
  };

  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "none" } }}>
        <TableCell component="th"
          scope="row"
          align="center"
          sx={{ width: "250px" }}
        >
          {row.application_name}
        </TableCell>
        <TableCell component="th"
          scope="row"
          align="center"
          sx={{ width: "150px" }}
        >
          {row.application_id}
        </TableCell>
        <TableCell
          component="th"
          scope="row"
          align="center"
          sx={{ width: "300px" }}
        >
          {row.repository_name.split('/')[1] || row.repository_name}
        </TableCell>
        <TableCell align="center">
          {row.primary_language}
        </TableCell>
        <TableCell align="center">
          {row.last_commit_author}
        </TableCell>
        <TableCell align="center">
          {row.link_to_repo}
        </TableCell>
        <TableCell align="center">
            <FaCheckCircle
                style={{ color: "green" }}
                title="Verified"
                size={20}
            />
        </TableCell>
        {isciowner === true ? (
            <TableCell align="center" sx={{ verticalAlign: "center" }}>
            <Icon
                name="trash"
                size={24}
                style={{ display: "flex", cursor: "pointer" }}
                onClick={() => {
                setshowConfirmation(true);
                }}
            />
            </TableCell>
          ) : (
            <TableCell align="center">
                {" "}
            </TableCell>
        )}
      </TableRow>
      {showConfirmation && (
        <div className="confirmation-popup">
          <p>Are you sure you want to delete?</p>
          <div className="centered-elements">
            <button className="yes" onClick={handleDeleteClick}>
              Yes
            </button>
            <button
              className="no"
              onClick={() => {
                setshowConfirmation(false);
              }}
            >
              No
            </button>
          </div>
        </div>
      )}
    </React.Fragment>
  );
}


function Table9({ data9, setProgress, fetchRepoData }) {
  console.log(data9);
  const data9Formatted = data9?.[0]?.certified_data.map(repo => [
    repo.ApplicationName,
    repo.ApplicationId,
    repo.nameWithOwner,
    repo.primaryLanguage,
    repo.lastCommitAuthor,
    repo.url
  ]);
  const data = data9Formatted;
  console.log(data);
  const isciowner = data9?.[0]?.isciowner;

  const [sortState9, setSortState9] = useState({ columnIndex: null, order: null });
  const handleSort = (colIndex, direction) => {
    setSortState9({
      columnIndex: colIndex,
      order: direction,
    });

    const tbody = document.querySelector('tbody');
    const rows = Array.from(tbody.querySelectorAll('tr'));

    // Group each parent row and its corresponding expandable row as a pair
    const rowPairs = [];
    for (let i = 0; i < rows.length; i++) {
      const parentRow = rows[i];
      const nextRow = rows[i + 1];
      // Check if the next row is expandable (i.e., has colspan attribute)
      if (nextRow && nextRow.querySelector('td[colspan]')) {
        rowPairs.push([parentRow, nextRow]); // Pair the parent row with its expandable row
        i++; // Skip the next row since it's already grouped with its parent
      } else {
        rowPairs.push([parentRow]); // In case there is no expandable row
      }
    }

    // Sort only the parent rows based on the selected column
    const sortedPairs = rowPairs.sort((a, b) => {
      const valueA = a[0].children[colIndex].innerText.toLowerCase();
      const valueB = b[0].children[colIndex].innerText.toLowerCase();

      // Handle numerical values
      const numA = parseFloat(valueA);
      const numB = parseFloat(valueB);

      if (!isNaN(numA) && !isNaN(numB)) {
        return direction === "asc" ? numA - numB : numB - numA;
      }

      // String comparison
      if (valueA < valueB) return direction === "asc" ? -1 : 1;
      if (valueA > valueB) return direction === "asc" ? 1 : -1;
      return 0;
    });

    // Clear the table body
    while (tbody.firstChild) {
      tbody.removeChild(tbody.firstChild);
    }

    // Append the sorted parent rows along with their expandable rows
    sortedPairs.forEach(pair => {
      pair.forEach(row => tbody.appendChild(row));
    });
  };

  return (
    <div>
      <div
        style={{
          margin: "auto",
          width: "90%",
          marginTop: "30px",
          // maxHeight:"40vh",
          // overflowY: "auto",
          // maxWidth: "fit-content",
          border: "2px solid #87CEEB",
          display: "flex",
          zIndex: "-10",
        }}
      >
        {/* <div style={{width:"300px"}}>
        <TableCell
                  sx={{ borderBottom:"2px solid #87CEEB",width:"300px",fontSize:"1rem", }}
                  align="center"
                >
                  Filters
                </TableCell>

        </div> */}

        <TableContainer
          component={Paper}
          sx={{ width: "100%", maxHeight: "400px" }}
        >
          <Table
            stickyHeader
            aria-label="sticky table"
            sx={{ "& .MuiTableCell-root": { fontSize: "1rem" } }}
          >
            <TableHead>
              <TableRow>
                {["Application CI", "App ID", "Repository Name", "Primary Language", "Last Commit Author", "Link to Repository", ""].map((header, index) => (
                  <TableCell
                    key={index}
                    sx={{ backgroundColor: "#87CEEB", color: "white", border: "2px solid #87CEEB" }}
                    align="center"
                  >
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                      {header}
                      {index!==6 && index!==7 &&
                      <div style={{ display: "flex", flexDirection: "column", marginLeft: "10px" }}>
                        {sortState9.columnIndex === index && sortState9.order === "asc" ? (
                          <KeyboardArrowDownIcon
                            style={{ cursor: "pointer", fontSize: "24px", fontWeight: "bold" }}
                            onClick={() => handleSort(index, "desc")}
                          />
                        ) : (
                          sortState9.columnIndex !== index && (
                            <KeyboardArrowUpIcon
                              style={{ cursor: "pointer", fontSize: "16px", fontWeight: "bold" }}
                              onClick={() => handleSort(index, "asc")}
                            />
                          )
                        )}
                        {sortState9.columnIndex === index && sortState9.order === "desc" ? (
                          <KeyboardArrowUpIcon
                            style={{ cursor: "pointer", fontSize: "24px", fontWeight: "bold" }}
                            onClick={() => handleSort(index, "asc")}
                          />
                        ) : (
                          sortState9.columnIndex !== index && (
                            <KeyboardArrowDownIcon
                              style={{ cursor: "pointer", fontSize: "16px", fontWeight: "bold" }}
                              onClick={() => handleSort(index, "desc")}
                            />
                          )
                        )}
                      </div>}
                    </div>
                  </TableCell>
                ))}
                <TableCell
                    
                    sx={{ backgroundColor: "#87CEEB", color: "white", border: "2px solid #87CEEB" }}
                    align="center"
                  ></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data !== undefined &&
                Array.isArray(data) &&
                data && data
                  .map((repo) => (
                    <Row
                        key={repo[0]}
                        row={{
                            application_name: repo[0],
                            application_id: repo[1],
                            repository_name: repo[2],
                            primary_language: repo[3],
                            last_commit_author: repo[4],
                            link_to_repo: (
                                <a href={repo[5]} target="_blank" rel="noopener noreferrer" style={{ color: "#42b0d5" }}>
                                  {repo[2]} {/* Displaying repository name as link text */}
                                </a>
                            ),
                        }}
                        isciowner={isciowner}
                    />
                ))
            }
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
}

export default Table9;