import { TableContext } from "../context";
import React from "react";
import "../App.css";
import { saveAs } from "file-saver";
import Papa from "papaparse";
//  import '../helper.js';
import DeleteConfirmation from "./DeleteConfirmation";
import {
  useRef,
  useLayoutEffect,
  useContext,
  useState,
  useEffect,
} from "react";
import { IoMdDownload } from "react-icons/io";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Icon } from "@anchor/react-components";
import { Tooltip } from "react-tooltip";
import { RxQuestionMarkCircled } from "react-icons/rx";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'; 
/**
 * Table8 component renders a table with filtering and download functionality.
 * 
 * @component
 * @param {Object} props - The properties object.
 * @param {string} props.FilterIndex - The index for the first filter.
 * @param {string} props.FilterIndex2 - The index for the second filter.
 * @param {string} props.Header1 - The header for the first column.
 * @param {string} props.Header2 - The header for the second column.
 * @param {string} props.Header3 - The header for the third column.
 * @param {string} props.Header4 - The header for the fourth column.
 * @param {string} props.Header5 - The header for the fifth column.
 * @param {string} props.Header6 - The header for the sixth column.
 * @param {string} props.Header7 - The header for the seventh column.
 * @param {string} props.Header8 - The header for the eighth column.
 * @param {string} props.Header9 - The header for the ninth column.
 * @param {function} props.setshowCommit - Function to set the show commit state.
 * 
 * @returns {JSX.Element} The rendered Table8 component.
 * 
 * @example
 * <Table8 
 *   FilterIndex={0} 
 *   FilterIndex2={1} 
 *   Header1="Server Name" 
 *   Header2="Status" 
 *   Header3="Application" 
 *   Header4="Environment" 
 *   Header5="Owner" 
 *   Header6="Location" 
 *   Header7="Type" 
 *   Header8="Category" 
 *   Header9="Notes" 
 *   setshowCommit={setShowCommit} 
 * />
 */
/**
 * Table8 Component
 * 
 * This component renders a table with data that can be filtered and downloaded as a CSV file.
 * It provides functionalities to filter the table rows based on selected criteria, download the table data as a CSV file, and handle delete requests for specific rows.
 * 
 * @component
 * @param {Object} props - The properties object. * @param {string} props.FilterIndex - The index of the first filter column.x for the first filter.
 * @param {string} props.FilterIndex2 - The index for the second filter.
 * @param {string} props.Header1 - The header for the first column.
 * @param {string} props.Header2 - The header for the second column.
 * @param {string} props.Header3 - The header for the third column.
 * @param {string} props.Header4 - The header for the fourth column.
 * @param {string} props.Header5 - The header for the fifth column.
 * @param {string} props.Header6 - The header for the sixth column.
 * @param {string} props.Header7 - The header for the seventh column.
 * @param {string} props.Header8 - The header for the eighth column.
 * @param {string} props.Header9 - The header for the ninth column.
 * @param {function} props.setshowCommit - Function to set the commit state.
 * 
 * @returns {JSX.Element} The rendered Table8 component.
 */
export default function Table8(props) {
  const {
    table8,
    setTable8,
    deletedRows,
    setDeletedRows,
    graphData,
    serverData,
    tableRightBoundary,
    setTableRightBoundary,
    tableBottomtBoundary,
    setTableBottomBoundary,
    fetchWithTimeout,
    relationshipCategorySelected, 
    token
  } = useContext(TableContext);
  const heightRef = useRef();
  const uniqueValuesSet = new Set([
    ...(Array.isArray(table8)
      ? table8.map((row) => row[props.FilterIndex])
      : []),
    ...(Array.isArray(serverData)
      ? serverData
          .filter((innerList) => innerList[9] === "create")
          .map((row) => row[2])
      : []),
  ]);

  const [uniqueValues2, setUniqueValues2] = useState();
  const [selectedFilter1, setSelectedFilter1] = useState("All");
  const [selectedFilter2, setSelectedFilter2] = useState("All");
  const [selectedserver, setSelectedServer] = useState("");

  const uniqueValues = Array.from(uniqueValuesSet);
  useEffect(() => {
    const uniqueValuesSet2 = new Set([
      ...(Array.isArray(table8)
        ? table8.map((row) => row[props.FilterIndex2])
        : []),
      ...(Array.isArray(serverData)
        ? serverData
            .filter((innerList) => innerList[9] === "create")
            .map((row) => row[7])
        : []),
    ]);
    setUniqueValues2(
      Array.from(uniqueValuesSet2).filter(
        (value) => value !== null && value.trim() !== ""
      )
    );
  }, [table8, serverData]);

  const [sortState8, setSortState8] = useState({ columnIndex: null, order: null });
  const handleSort = (colIndex, direction) => {
    setSortState8({
      columnIndex: colIndex,
      order: direction,
    });
  
    const tbody = document.querySelector('tbody');
    const rows = Array.from(tbody.querySelectorAll('tr'));
  
    const sortedRows = rows.sort((a, b) => {
      const valueA = a.children[colIndex].innerText.toLowerCase();
      const valueB = b.children[colIndex].innerText.toLowerCase();
  
      // Handle sorting for numerical strings, mixed-case strings, or other complex formats
      // If both values are numbers, sort numerically
      const numA = parseFloat(valueA);
      const numB = parseFloat(valueB);
      
      if (!isNaN(numA) && !isNaN(numB)) {
        return direction === "asc" ? numA - numB : numB - numA;
      }
  
      // String comparison (case-insensitive alphabetical sorting)
      if (valueA < valueB) return direction === "asc" ? -1 : 1;
      if (valueA > valueB) return direction === "asc" ? 1 : -1;
      return 0; // if values are equal
    });
  
    // Clear the existing rows
    while (tbody.firstChild) {
      tbody.removeChild(tbody.firstChild);
    }
  
    // Append the sorted rows
    sortedRows.forEach(row => tbody.appendChild(row));
  };

  /**
   * Handles the download button click event to generate and download a CSV file.
   * 
   * This function uses the PapaParse library to convert table data into a CSV format
   * and triggers a download of the generated CSV file. The headers for the CSV are 
   * passed as props to the component.
   * 
   * @function handleDownloadClick
   * @returns {void}
   */
  const handleDownloadClick = () => {
    const headers = [
      props.Header1,
      props.Header2,
      props.Header3,
      props.Header4,
      props.Header5,
      props.Header6,
      props.Header7,
      props.Header8,
      props.Header9,
    ];
    // Assuming table8 is available in the scope and headers are passed as props
    const csv = Papa.unparse({
      fields: headers,
      data: table8,
    });
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    saveAs(blob, "Server to App.csv");
  };

  useEffect(() => {
    /**
     * Filters the rows of a table based on the selected filters.
     * 
     * This function retrieves the table element with the ID "data-table" and filters its rows
     * based on the values of `selectedFilter1` and `selectedFilter2`. The filtering is done
     * by comparing the text content of specific table cells (determined by `props.FilterIndex`
     * and `props.FilterIndex2`) with the selected filter values.
     * 
     * Rows that match both filters will be displayed, while rows that do not match will be hidden.
     * 
     * @function
     */
    const filterTable = () => {
      const table = document.getElementById("data-table");
      const tbody = table?.querySelector("tbody");
      const rows = tbody?.querySelectorAll("tr");

      rows.forEach((row) => {
        const cell1 = row.querySelector(
          `td:nth-child(${props.FilterIndex + 1})`
        );
        const cell2 = row.querySelector(
          `td:nth-child(${props.FilterIndex2 + 1})`
        );
        console.log(selectedFilter1, selectedFilter2);
        const matchFilter1 =
          selectedFilter1 === "All" || cell1.textContent === selectedFilter1;
        const matchFilter2 =
          selectedFilter2 === "All" || cell2.textContent === selectedFilter2;

        if (matchFilter1 && matchFilter2) {
          row.style.display = "";
        } else {
          row.style.display = "none";
        }
      });
    };

    filterTable();
  }, [selectedFilter1, selectedFilter2, props.FilterIndex, props.FilterIndex2]);
  useEffect(() => {
    const filteredtable8 =
      selectedFilter1 === "All"
        ? table8
        : table8.filter((row) => row[props.FilterIndex] === selectedFilter1);
    // setUniqueValues2([...new Set(filteredtable8.filter(value => value !== null && value !== '').map((row) => row[props.FilterIndex2]))]);
    const filteredData =
      selectedFilter1 === "All"
        ? serverData
        : serverData.filter((row) => row[2] === selectedFilter1);
    setUniqueValues2([
      ...new Set(
        filteredtable8
          .map((row) => row[props.FilterIndex2])
          .filter((value) => value && value.trim() !== "")
      ),
      ...new Set(
        filteredData
          .map((row) => row[7])
          .filter((value) => value && value.trim() !== "")
      ),
    ]);
    const allRadioButton2 = document.querySelector(
      'input[name="filter2"][value="All"]'
    );
    if (allRadioButton2) {
      allRadioButton2.checked = true;
    }
    setSelectedFilter2("All");
  }, [selectedFilter1]);
  //   console.log(uniqueValues)
  useEffect(() => {
    localStorage.removeItem('serverTabId');
   }, []);

  /**
   * Handles the deletion of a specified value by making an asynchronous request to the server.
   * Displays appropriate toast notifications based on the response or errors encountered.
   *
   * @param {any} value - The value to be deleted.
   * @returns {Promise<void>} - A promise that resolves when the deletion process is complete.
   * @throws {Error} - Throws an error if the request times out or a server error occurs.
   */
  const handleDelete = async (value) => {
    try {
      const response = await fetchWithTimeout(`${process.env.REACT_APP_BACKEND_URI}/delete`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify({
          value: value,
          requested_by: graphData.displayName,
          graph_access_token : token,
        }),
      });
      const data = await response.json();
      if (data?.message.includes("Request for delete relation")) {
        toast.error(data.message, {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else if (data?.message.includes("Exception: No Owner")){
        toast.error("Restricted: Application has not CI owner assigned", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        setDeletedRows([...deletedRows, value]);
        console.log(deletedRows);
        props.setshowCommit(true);
        if (response["status"] == 200) {
          toast.success("Delete request is raised", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          toast.info("You can confirm your changes", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        } else {
          toast.error("Delete request is failed", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      }
    } catch (error) {
      if (error.message === "Timeout") {
        toast.error("Request Timed Out", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        toast.error("Server Error", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    }
  };

  /**
   * Updates the position of a button based on the boundaries of the table container.
   * 
   * This function calculates the right and bottom boundaries of the table container
   * and updates the state with these new values. It retrieves the table container
   * element by its ID ("data-table") and uses its bounding rectangle and offset
   * dimensions to determine the new boundaries.
   * 
   * @function
   * @name updateButtonPosition
   */
  const updateButtonPosition = () => {
    const tableContainer = document.getElementById("data-table");
    if (tableContainer) {
      var newTableRightBoundary =
        tableContainer.getBoundingClientRect().left +
        tableContainer.offsetWidth;
      var newTableBottomBoundary =
        tableContainer.getBoundingClientRect().bottom +
        tableContainer.offsetHeight;
      // if(document.documentElement.scrollWidth < window.innerWidth){
      //   // newTableRightBoundary+=(window.innerWidth-document.documentElement.scrollWidth);
      //  var a= window.innerWidth-document.documentElement.scrollWidth
      //   newTableRightBoundary-=a;
      //   console.log(typeof(a))
      //   console.log(typeof(newTableRightBoundary))
      // }
      setTableRightBoundary(newTableRightBoundary);
      setTableBottomBoundary(newTableBottomBoundary);
    }
  };
  useLayoutEffect(() => {
    updateButtonPosition();
  }, [table8]);
  useLayoutEffect(() => {
    // Initial update
    // updateButtonPosition();
    // getDimensions()
    // setTimeout(() => {
    //   getToken()
    // }, 2000);
    // getToken()
    const handleResize = () => {
      // setWindowWidth(window.innerWidth);
      // getDimensions()
      updateButtonPosition();
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // useEffect(() => {
  //   const tbody = document.querySelector("tbody");
  //   const downloadButton = document.getElementById("download");
  //   if (tbody?.scrollHeight > tbody?.clientHeight) {
  //     if (downloadButton) downloadButton.style.width = "6vw";
  //   } else {
  //     if (downloadButton) downloadButton.style.width = "4vw";
  //   }
  // }, [table8, data]);

  const handleServerClick = (value) => {
    const serverName = value[0];
    setSelectedServer(serverName);
    const existingTabId = localStorage.getItem('serverTabId');
    // console.log("EXISTINGTAB: ", existingTabId);
    if (!existingTabId) {
      // Open a new tab and store its reference
      const newTab = window.open(`/server-specifications?servername=${encodeURIComponent(serverName)}`, '_blank');
      if (newTab) {
        const newTabId = `serverTab-${Date.now()}`;
        newTab.name = newTabId;
        localStorage.setItem('serverTabId', newTabId);
        // console.log('NEWTAB: ', newTab);
      }
    } else {
      // Send a message to the existing tab to update its content
      const existingTabWindow = window.open('', existingTabId);
      // console.log("EXISTINGTABWINDOW: ", existingTabWindow);
      if (existingTabWindow && !existingTabWindow.closed) {
        existingTabWindow.postMessage({ serverName }, '*');
      } else {
        // If the existing tab is closed, open a new one
        const newTab = window.open(`/server-specifications?servername=${encodeURIComponent(serverName)}`, '_blank');
        if (newTab) {
          const newTabId = `serverTab-${Date.now()}`;
          newTab.name = newTabId;
          localStorage.setItem('serverTabId', newTabId);
        }
      }
    }
  };

  return (
    <div>
      <div
        style={{
          margin: "auto",
          width: "90%",
          // marginTop: "70px",

         
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <button
          style={{
            border: "none",
            borderRadius: "5px",
            padding: "6px",
            backgroundColor: "rgb(66, 176, 213)",
            color: "white",
            marginBottom: "5px",
            
          }}
          onClick={handleDownloadClick}
        >
          Extract 
          <IoMdDownload
            size={20}
            style={{
              marginLeft: "5px",
              
            }}
          />
        </button>
      </div>
      <div
        ref={heightRef}
        style={{
          margin: "auto",
          width: "90%",
          // marginTop: "70px",
          borderBottom: "2px solid #87CEEB",
          borderLeft: "2px solid #87CEEB",
          maxWidth: "fit-content",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            borderRight: "2px solid #87CEEB",
            borderTop: "2px solid #87CEEB",
            fontSize: "16px",
          }}
        >
          
        </div>
        <div
          id="filter"
          style={{
            float: "left",
            border: "2px solid #87CEEB",
            borderBottom: "none",
            borderLeft: "none",
            width: "175px",
            // height:heightRef.current?.offsetHeight,
            // minHeight: "200px",
            maxHeight: "350px",
            paddingTop: "7px",
            borderRight: "none",
          }}
        >
          <div style={{ borderBottom: "2px solid #87CEEB", height: "42px" }}>
            <label
              htmlFor="ciTypeFilter"
              style={{ margin: "4px", fontSize: "17px" }}
            >
              Filter By Server Type:
            </label>
          </div>
          <div
            className="filters"
            style={{ padding: "7px", overflowY: "auto", height: "120px" }}
          >
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                value="All"
                name="filter"
                id="All1"
                onChange={() => setSelectedFilter1("All")}
                defaultChecked
              />
              <label
                className="form-check-label"
                htmlFor="All1"
                style={{ fontSize: "16px" }}
              >
                All
              </label>
            </div>
            {uniqueValues.map((value, key) => {
              return (
                <div className="form-check" key={key}>
                  <input
                    className="form-check-input"
                    type="radio"
                    value={value}
                    id={value}
                    name="filter"
                    onChange={() => setSelectedFilter1(value)}
                  />
                  <label
                    className="form-check-label"
                    htmlFor={value}
                    style={{ fontSize: "16px" }}
                  >
                    {value}
                  </label>
                </div>
              );
            })}
          </div>
          <div style={{ borderBottom: "2px solid #87CEEB", height: "42px" }}>
            <label
              htmlFor="ciTypeFilter"
              style={{ margin: "4px", fontSize: "17px" }}
            >
              Filter By Application:
            </label>
          </div>
          <div
            className="filters"
            style={{ padding: "7px", overflowY: "auto", height: "132px" }}
          >
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                value="All"
                name="filter2"
                id="All2"
                onChange={() => setSelectedFilter2("All")}
              />
              <label
                className="form-check-label"
                htmlFor="All2"
                style={{ fontSize: "16px" }}
              >
                All
              </label>
            </div>
            {[... new Set(uniqueValues2)]?.map((value, key) => {
              return (
                <div className="form-check" key={key}>
                  <input
                    className="form-check-input"
                    type="radio"
                    value={value}
                    id={value}
                    name="filter2"
                    onChange={() => setSelectedFilter2(value)}
                  />
                  <label
                    className="form-check-label"
                    htmlFor={value}
                    style={{ fontSize: "16px" }}
                  >
                    {value}
                  </label>
                </div>
              );
            })}
          </div>
        </div>
        <div
          id="table"
          className="table-container"
          ref={heightRef}
          // style={{
          //   marginLeft: "20px",
          //   overflowY: "auto",
          //   tableLayout: "fixed",
          //   borderCollapse: "collapse",
          //   minHeight: "200px",
          //   maxHeight: "350px",
          //   maxWidth: "fit-content",
          //   // display:"table-row-group"
          // }}
        >
          <table id="data-table" className="custom-table exclude-width-rule">
            <thead>
              <tr>
                {[props.Header1, props.Header2, props.Header3, props.Header4, props.Header5, props.Header6, props.Header7, props.Header8, props.Header9].map((header, index) => (
                  <th key={index} style={{ border: "2px solid #87CEEB" }}>
                    <div style={{ display: "flex", justifyContent: "space-around", alignItems: "center" }}>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        {header}
                        <div style={{ display: "flex", flexDirection: "column", marginLeft: "5px" }}>
                          {sortState8.columnIndex === index && sortState8.order === "asc"  ? (
                            <KeyboardArrowDownIcon
                              style={{
                                cursor: "pointer",
                                fontSize: "24px",
                                fontWeight: "bold", 
                              }}
                              onClick={() => handleSort(index, "desc")}
                            />
                          ) : ( sortState8.columnIndex !== index && 
                            <KeyboardArrowUpIcon
                              style={{
                                cursor: "pointer",
                                fontSize: "16px",
                                fontWeight: "bold", 
                              }}
                              onClick={() => handleSort(index, "asc")}
                            />
                          )}
                          {sortState8.columnIndex === index && sortState8.order === "desc" ? (
                            <KeyboardArrowUpIcon
                              style={{
                                cursor: "pointer",
                                fontSize: "24px",
                                fontWeight: "bold", 
                              }}
                              onClick={() => handleSort(index, "asc")}
                            />
                          ) : ( sortState8.columnIndex !== index &&
                            <KeyboardArrowDownIcon
                              style={{
                                cursor: "pointer",
                                fontSize: "16px", 
                                fontWeight: "bold",
                              }}
                              onClick={() => handleSort(index, "desc")}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </th>
                  )
                )}
                <th
                  id="download"
                  style={{ border: "2px solid #87CEEB", width: "5vw" }}
                >
                 {" "}
                </th>
              </tr>
            </thead>
            <tbody>
              {serverData
                .filter((innerList) => innerList[9] === "create")
                .map((value, key) => {
                  return (
                    <tr
                      key={key}
                      id={key}
                      style={{ background: "rgb(178, 233, 205)" }}
                    >
                      <td style={{ border: "2px solid #87CEEB", width:'9vw' }}>
                        {value[0]}
                        <span
                          style={{
                            marginLeft: "10px",
                            display: "inline-block",
                            position: "relative",
                            top: "6px",
                          }}
                          data-tooltip-id="create-info"
                          data-tooltip-content={
                            "Create requested by " +
                            value[11] +
                            " at " +
                            value[10] +
                            " GMT"
                          }
                          data-tooltip-place="bottom-start"
                        >
                          <Icon name="info-circle" size="24" />
                        </span>
                        <Tooltip id="create-info" />
                        <RxQuestionMarkCircled
                          size={20}
                          style={{
                            marginLeft: "2px",
                            verticalAlign: "text-bottom",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            handleServerClick(value);
                          }}
                        />
                      </td>
                      <td style={{ border: "2px solid #87CEEB" }}>
                        {value[1]}
                      </td>
                      <td style={{ border: "2px solid #87CEEB" }}>
                        {value[2]}
                      </td>
                      <td style={{ border: "2px solid #87CEEB" }}>
                        {value[3]}
                      </td>
                      <td style={{ border: "2px solid #87CEEB" }}>
                        {value[4]}
                      </td>
                      <td style={{ border: "2px solid #87CEEB"}}>
                        {value[5]}
                      </td>
                      <td style={{ border: "2px solid #87CEEB" }}>
                        {value[6]}
                      </td>
                      <td style={{ border: "2px solid #87CEEB" }}>
                        {value[7]}
                      </td>
                      <td style={{ border: "2px solid #87CEEB" }}>
                        {value[8]}
                      </td>
                      <td
                        style={{
                          border: "2px solid #87CEEB",
                          textAlign: "center",
                          width: "5vw",
                        }}
                      >
                        {/* <DeleteConfirmation onDelete={() => {handleDelete(value);
                        // makeRed(key);
                        } } value={value}/> */}
                      </td>
                    </tr>
                  );
                })}
              {table8.map((value, key) => {
                return (
                  <tr
                    key={key}
                    id={key}
                    style={{
                      background: serverData.some(
                        (subArray) =>
                          subArray.includes(value[0]) &&
                          subArray.includes(value[7]) &&
                          subArray[9] === "delete"
                      )
                        ? "rgba(233, 178, 183, 1)"
                        : value[1] === "Decommissioned"
                        ? "rgba(211, 211, 211, 1)"
                        : "none",
                      textDecoration: serverData.some(
                        (subArray) =>
                          subArray.includes(value[0]) &&
                          subArray.includes(value[7]) &&
                          subArray[9] === "delete"
                      )
                        ? "line-through"
                        : "none",
                        // opacity: value[5] === "Decommissioned" ? "0.8" : "1",
                    }}
                  >
                    <td style={{ border: "2px solid #87CEEB", width: '9vw'}}>
                      {value[0]}
                      <span
                        style={{
                          marginLeft: "10px",
                          display: serverData.some(
                            (subArray) =>
                              subArray.includes(value[0]) &&
                              subArray.includes(value[7])
                          )
                            ? "inline-block"
                            : "none",
                          position: "relative",
                          top: "6px",
                        }}
                        data-tooltip-id="delete-info"
                        data-tooltip-content={serverData
                          .filter(
                            (innerList) =>
                              innerList.includes(value[0]) &&
                              innerList.includes(value[7]) &&
                              innerList[9] === "delete"
                          )
                          .map((item, key) => {
                            return (
                              "Delete requested by " +
                              item[11] +
                              " at " +
                              item[10] +
                              " GMT"
                            );
                          })}
                        data-tooltip-place="bottom-start"
                      >
                        <Icon style={{}} name="info-circle" size="24" />
                      </span>
                      <Tooltip id="delete-info" />
                      <RxQuestionMarkCircled
                          size={20}
                          style={{
                            marginLeft: "2px",
                            verticalAlign: "text-bottom",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            handleServerClick(value);
                          }}
                        />
                    </td>
                    <td style={{ border: "2px solid #87CEEB" }}>{value[1]}</td>
                    <td style={{ border: "2px solid #87CEEB" }}>{value[2]}</td>
                    <td style={{ border: "2px solid #87CEEB" }}>{value[3]}</td>
                    <td style={{ border: "2px solid #87CEEB" }}>{value[4]}</td>
                    <td style={{ border: "2px solid #87CEEB" }}>{value[5]}</td>
                    <td style={{ border: "2px solid #87CEEB" }}>{value[6]}</td>
                    <td style={{ border: "2px solid #87CEEB", color: value[7] === "Orphan Server" ? "red" : "inherit" }}>{value[7]}
                    <span
                        style={{
                          marginLeft: "10px",
                          display: value[7] === "Orphan Server" 
                            ? "inline-block"
                            : "none",
                          position: "relative",
                          top: "6px",
                        }}
                        data-tooltip-id="orphan-sever"
                        data-tooltip-content={`${value[0]} is not associated with any application`}
                        data-tooltip-place="bottom-start"
                      >
                        <Icon style={{}} name="info-circle" size="24" />
                      </span>
                      <Tooltip id="orphan-sever" />
                    </td>
                    <td style={{ border: "2px solid #87CEEB" }}>{value[8]}</td>
                    <td
                      style={{
                        border: "2px solid #87CEEB",
                        textAlign: "center",
                        width: "5vw"
                        // width: "125px",
                      }}
                    >
                      
                      <DeleteConfirmation
                        onDelete={() => {
                          handleDelete([value[7],'Hosted on::Hosts',value[0]]);
                          // makeRed(key);
                        }}
                        value={value}
                      />  
                    </td>
                    {/* {false && (
                        <>
                        <td>{value[9]}</td>
                        <td>{value[10]}</td>
                        <td>{value[11]}</td>
                        </>)} */}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
