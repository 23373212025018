import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  TextField,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  List,
  ListItem,
  Divider,
  Chip,
  Paper,
  Card,
  CardContent,
  CircularProgress
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const DataRenderer = ({ data, label = "" }) => {
  const renderData = (key, value) => {
    if (typeof value === "string" || typeof value === "number") {
      return (
        <Card key={key} variant="outlined" sx={{ mb: 2, height: "fit-content", overflowX: "scroll" }}>
          <CardContent sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", overflowX: "scroll", padding: "10px", "&:last-child": { paddingBottom: "8px", alignItems: "center" } }}>
            <Typography variant="subtitle2" gutterBottom sx={{ textAlign: 'left' }}>
              {key}
            </Typography>
            <Typography variant="body2" color="textSecondary" sx={{ textAlign: 'right', backgroundColor: "rgba(211, 211, 211, 1)", borderRadius: "8px", padding: "8px", width: "fit-content" }}>
              {value}
            </Typography>
          </CardContent>
        </Card>
      );
    } else if (Array.isArray(value)) {
      return (
        <Box key={key} sx={{ mb: 2 }}>
          <Typography variant="subtitle2" gutterBottom sx={{ textAlign: 'left' }}>
            {key}
          </Typography>
          <List dense sx={{ width: '100%' }}>
            {value.map((item, index) => (
              <ListItem key={index} disablePadding sx>
                <DataRenderer data={item} label={`${key}[${index}]`} />
              </ListItem>
            ))}
          </List>
        </Box>
      );
    } else if (typeof value === "object" && value !== null) {
      return (
        <Accordion key={key} sx={{ mb: 2, transition: 'transform 0.3s, box-shadow 0.3s', '&:hover': { transform: 'translateY(-3px)', boxShadow: 3 } }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>{key}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <DataRenderer data={value} />
          </AccordionDetails>
        </Accordion>
      );
    } else {
      return null;
    }
  };

  return (
    <Box>
      {label && (
        <Typography variant="h6" gutterBottom>
          {label}
        </Typography>
      )}
      {Object.entries(data).map(([key, value]) => renderData(key, value))}
    </Box>
  );
};

const ResourcePopUp = ({ rowData }) => {
  const [jsonData, setJsonData] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URI}/getresourcemetadata?resource_name=${encodeURIComponent(
          rowData['Resource Name'])}&resource_id=${encodeURIComponent(rowData['Account ID'])}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        if (data?.data && typeof data.data !== 'object') {
          setError(true);
          return;
        }
        setJsonData(data?.data);
      } catch (error) {
        setError(true);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [rowData]);

  return (
    <Paper
      sx={{
        margin: "0 auto",
        padding: "20px",
        maxHeight: "60vh",
        overflowY: "auto",
        width: "95%"
      }}
    >
      <Typography variant="h4" gutterBottom textAlign="center">
        Specifications
      </Typography>
      <Divider sx={{ mb: 2 }} />
      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '30vh' }}>
          <CircularProgress />
        </Box>
      ) : error ? (
        <Typography variant="h6" textAlign="center">
          No data found
        </Typography>
      ) : (
        <DataRenderer data={jsonData} />
      )}
    </Paper>
  );
};

export default ResourcePopUp;