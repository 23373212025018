// Importing necessary libraries and components
import React, { useEffect, useState, useLayoutEffect } from "react";
import Table2 from "./Table2";
import { Icon } from "@anchor/react-components";
import "../App.css";
import { TableContext } from "../context";
import { useContext } from "react";
import { toast } from "react-toastify";
// ViewChanges component
/**
 * ViewChanges component fetches and displays changes related to a user.
 *
 * @component
 * @param {Object} props - The properties object.
 * @param {Function} props.setProgress - Function to set the progress of data fetching.
 * @returns {JSX.Element} The rendered component.
 *
 * @example
 * <ViewChanges setProgress={setProgressFunction} />
 *
 * @description
 * This component uses the `useLayoutEffect` hook to fetch data from the server when the component mounts or when `graphData` or `table1.length` changes. It displays the fetched data in a table if available, otherwise shows a message indicating no changes were made.
 *
 * @function
 * @name ViewChanges
 *
 * @param {Object} props - The component props.
 * @param {Function} props.setProgress - Function to update the progress indicator.
 *
 * @returns {JSX.Element} The rendered component.
 */
export default function ViewChanges(props) {
  // State for the table data
  const [table1, setTable1] = useState([]);
  // Context for the graph data
  const { graphData, setGraphData, fetchUserDetails,fetchWithTimeout } =
    useContext(TableContext);
  // Effect hook for fetching data when the component mounts or when graphData or table1.length changes
  useLayoutEffect(() => {
    if(graphData?.displayName!==undefined)
      {
        getData();

      }
  }, [graphData, table1.length]);
  
  // Function for fetching data from the server
  const getData = async () => {
    // Set progress to 10
    props.setProgress(10);
    try {
      // Fetch data from the server
      await fetchWithTimeout(
        `${process.env.REACT_APP_BACKEND_URI}/viewchanges?username=${encodeURIComponent(graphData?.displayName)}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
        .then((res) => res.json())
        .then((data) => {
          // Set the fetched data to the state
          setTable1(data);
          // Set progress to 70
          props.setProgress(70);
        });
      // Set progress to 100
      props.setProgress(100);
    } catch (error) {
      if(error.message==="Timeout"){
        toast.error("Request Timed Out", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
      // Handle error
    }
  };
  // Handler for icon click
  const onIconClick = () => {
    console.log("Click");
  };
  // Rendering the ViewChanges component
  return (
    <div>
      {/* Conditional rendering based on the length of table1 */}
      {table1.length > 0 && (
        <Table2
          table1={table1}
          FilterIndex={0}
          FilterIndex2={6}
          Header1="App Name"
          Header2="Related To"
          Header3="Created"
          Header4="Action"
          Header5="App Owner"
          Header6="Committed"
          Header7="Relationship Category"
          Header8="Server Env"
 
          getData={getData}
        />
      )}
      {table1.length == 0 && (
        <div style={{ textAlign: "center", marginTop: "200px" }}>
          No changes made
        </div>
      )}
    </div>
  );
}
