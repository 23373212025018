import React, { useState, useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import "../App.css";
import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import Footer from './Footer';
import { FaTimes } from 'react-icons/fa';
import { TableContext } from "../context";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField } from '@mui/material';
import { Card, CardContent, Typography, Box } from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const GeneralInfo = ({ data }) => {
  return (
    <div className="server-info">
      <div className="server-info-container">
        <span className="server-info-title">General Information</span>
        <div className="server-info-content">
          <div className="server-info-column">
            {[
              { label: 'Computer Name', value: data.computer_name },
              { label: 'Created At', value: data.created_at },
              { label: 'Host Name', value: data.hostname },
              { label: 'Platform', value: data.platform_like },
              { label: 'UUID', value: data.uuid },
              { label: 'Policy Updated At', value: data.policy_updated_at },
              { label: 'OS Query Host ID', value: data.osquery_host_id },
              { label: 'OS Query Version', value: data.osquery_version },
              { label: 'Code Name', value: data.code_name },
              { label: 'Updated At', value: data.updated_at },
            ].map((item, index) => (
              <div key={index} className="server-info-item">
                <p className="server-info-label">{item.label}</p>
                <input
                  type="text"
                  value={item.value}
                  disabled
                  className="server-info-input"
                />
              </div>
            ))}
          </div>
          <div className="server-info-column" style={{marginRight: "40px"}}>
            {[
              { label: 'Detail Updated At', value: data.detail_updated_at },
              { label: 'Last Enrolled At', value: data.last_enrolled_at },
              { label: 'Node Key', value: data.node_key },
              { label: 'Seen Time', value: data.seen_times },
              { label: 'Uptime Days', value: data.uptime_days },
              { label: 'Uptime Hours', value: data.uptime_hours },
              { label: 'Uptime Minutes', value: data.uptime_minutes },
              { label: 'Uptime Seconds', value: data.uptime_seconds },
              { label: 'Uptime Milliseconds', value: data.uptime_miliseconds },
            ].map((item, index) => (
              <div key={index} className="server-info-item">
                <p className="server-info-label">{item.label}</p>
                <input
                  type="text"
                  value={item.value}
                  disabled
                  className="server-info-input"
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

const HardwareSpecs = ({ data }) => {
  return (
    <div className="server-info">
      <div className="server-info-container">
        <span className="server-info-title">Hardware Specifications - System Configuration</span>
        <div className="server-info-content">
          <div className="server-info-column">
            {[
              { label: 'Memory', value: data.memory },
              { label: 'CPU Type', value: data.cpu_type },
              { label: 'CPU Subtype', value: data.cpu_subtype },
              { label: 'CPU Brand', value: data.cpu_brand },
              { label: 'CPU Physical Cores', value: data.cpu_physical_cores },
              { label: 'CPU Logical Cores', value: data.cpu_logical_cores },
              { label: 'RAM MB', value: data.ram_mb },
              { label: 'Hardware Vendor', value: data.hardware_vendor },
              { label: 'Hardware Model', value: data.hardware_model },
              { label: 'Hardware Version', value: data.hardware_version },
              { label: 'Hardware Serial', value: data.hardware_serial },
            ].map((item, index) => (
              <div key={index} className="server-info-item">
                <p className="server-info-label">{item.label}</p>
                <input
                  type="text"
                  value={item.value}
                  disabled
                  className="server-info-input"
                />
              </div>
            ))}
          </div>
          <div className="server-info-column" style={{marginRight: "40px"}}>
            {[
              { label: 'Primary IP', value: data.primary_ip },
              { label: 'Primary IP ID', value: data.primary_ip_id },
              { label: 'Primary MAC', value: data.primary_mac },
              { label: 'Public IP', value: data.public_ip },
              { label: 'Distributed Interval', value: data.distributed_interval },
              { label: 'Logger TLS Period', value: data.logger_tls_period },
              { label: 'Config TLS Refresh', value: data.config_tls_refresh },
              { label: 'Orbit Node Key', value: data.orbit_node_key },
              { label: 'CPU Speed', value: data.cpu_speed },
              { label: 'CPU Manufacturer', value: data.cpu_manufacturer },
            ].map((item, index) => (
              <div key={index} className="server-info-item">
                <p className="server-info-label">{item.label}</p>
                <input
                  type="text"
                  value={item.value}
                  disabled
                  className="server-info-input"
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

function DiskInfo({ diskData }) {
  const data = diskData.disks;
  return (
    <div style={{ padding: "10px" }}>
      <div style={{ width: "fit-content", padding: "0 20px" }}>
        <span style={{ marginBottom: "5px", fontSize: "25px" }}>Hardware Specifications - Disk Information</span>
        <Box 
          sx={{
            maxHeight: '55vh', 
            overflowY: 'auto',  
            marginTop: '20px',  
            width: '600px',
            padding: '10px',
          }}
        >
          {data.map((disk, index) => (
            <Card key={index} sx={{ 
              marginBottom: '20px',
              width: '100%',
              padding: '20px',
            }}>
              <CardContent>
                <Typography variant="h5" component="div" sx={{ marginBottom: '12px', fontSize: '1.5rem' }}>
                  Disk {index + 1}
                </Typography>
                <Typography color="text.secondary" sx={{ marginBottom: '8px', fontSize: '1.2rem' }}>
                  Host ID: {disk.host_id}
                </Typography>
                <Typography color="text.secondary" sx={{ marginBottom: '8px', fontSize: '1.2rem' }}>
                  Disk Space Available: {disk.gigs_disk_space_available} GB
                </Typography>
                <Typography color="text.secondary" sx={{ marginBottom: '8px', fontSize: '1.2rem' }}>
                  Percent Disk Space Available: {disk.percent_disk_space_available}%
                </Typography>
                <Typography color="text.secondary" sx={{ marginBottom: '8px', fontSize: '1.2rem' }}>
                  Created At: {new Date(disk.created_at).toLocaleString()}
                </Typography>
                <Typography color="text.secondary" sx={{ marginBottom: '8px', fontSize: '1.2rem' }}>
                  Last Updated At: {new Date(disk.updated_at).toLocaleString()}
                </Typography>
                <Typography color="text.secondary" sx={{ marginBottom: '8px', fontSize: '1.2rem' }}>
                  Encrypted: {disk.encrypted === 1 ? 'Yes' : 'No'}
                </Typography>
              </CardContent>
            </Card>
          ))}
        </Box>
      </div>
    </div>
  );
}

const InstalledSoftware = ({ softwareData }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [sortState1, setSortState1] = useState({ column: null, order: null });
  const data = softwareData.software;
  const headers = ['Name', 'Version', 'Source', 'Vendor'];

  const columnWidths = {
    name: '600px',
    version: '300px',
    source: '300px',
    vendor: '400px',
  };

  // Filter data based on search query
  const filteredData = data.filter((software) =>
    headers.some((header) =>
      software[header.toLowerCase()].toLowerCase().includes(searchQuery.toLowerCase())
    )
  );

  const handleSort = (index, direction) => {
    setSortState1({
      column: index,
      order: direction,
    });

    const tbody = document.querySelector('tbody');
    const rows = Array.from(tbody.querySelectorAll('tr'));

    // Group each parent row and its corresponding expandable row as a pair
    const rowPairs = [];
    for (let i = 0; i < rows.length; i++) {
      const parentRow = rows[i];
      const nextRow = rows[i + 1];
      // Check if the next row is expandable (i.e., has colspan attribute)
      if (nextRow && nextRow.querySelector('td[colspan]')) {
        rowPairs.push([parentRow, nextRow]); // Pair the parent row with its expandable row
        i++; // Skip the next row since it's already grouped with its parent
      } else {
        rowPairs.push([parentRow]); // In case there is no expandable row
      }
    }

    // Sort only the parent rows based on the selected column
    const sortedPairs = rowPairs.sort((a, b) => {
      const valueA = a[0].children[index].innerText.toLowerCase();
      const valueB = b[0].children[index].innerText.toLowerCase();

      // Handle numerical values
      const numA = parseFloat(valueA);
      const numB = parseFloat(valueB);

      if (!isNaN(numA) && !isNaN(numB)) {
        return direction === "asc" ? numA - numB : numB - numA;
      }

      // String comparison
      if (valueA < valueB) return direction === "asc" ? -1 : 1;
      if (valueA > valueB) return direction === "asc" ? 1 : -1;
      return 0;
    });

    // Clear the table body
    while (tbody.firstChild) {
      tbody.removeChild(tbody.firstChild);
    }

    // Append the sorted parent rows along with their expandable rows
    sortedPairs.forEach(pair => {
      pair.forEach(row => tbody.appendChild(row));
    });
  };

  return (
    <div style={{ padding: "10px" }}>
      <div style={{ width: "fit-content", padding: "0 20px" }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <span style={{ marginBottom: "10px", fontSize: "25px" }}>Software Specifications - Installed Software</span>
          <TextField
            label="Search"
            variant="outlined"
            size="small"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            style={{ marginBottom: '10px', width: '300px' }}
          />
        </div>
        <TableContainer component={Paper} sx={{ maxHeight: '55vh', overflow: 'auto', marginTop: '10px' }}>
          <Table stickyHeader aria-label="installed software table" sx={{ fontSize: '16px' }}>
            <TableHead>
              <TableRow>
                {headers.map((header, index) => (
                  <TableCell key={index} style={{ width: columnWidths[header.toLowerCase()] || 'auto' }} sx={{ fontSize: '16px' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      {header}
                      <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '10px' }}>
                        {sortState1.column === index && sortState1.order === 'asc' ? (
                          <KeyboardArrowDownIcon
                            style={{ cursor: 'pointer', fontSize: '24px', fontWeight: "bold" }}
                            onClick={() => handleSort(index, "desc")}
                          />
                        ) : (
                          sortState1.column !== index && (
                            <KeyboardArrowUpIcon
                              style={{ cursor: 'pointer', fontSize: '16px', fontWeight: "bold" }}
                              onClick={() => handleSort(index, "asc")}
                            />
                          )
                        )}
                        {sortState1.column === index && sortState1.order === "desc" ? (
                          <KeyboardArrowUpIcon
                            style={{ cursor: "pointer", fontSize: "24px", fontWeight: "bold" }}
                            onClick={() => handleSort(index, "asc")}
                          />
                        ) : (
                          sortState1.column !== index && (
                            <KeyboardArrowDownIcon
                              style={{ cursor: "pointer", fontSize: "16px", fontWeight: "bold" }}
                              onClick={() => handleSort(index, "desc")}
                            />
                          )
                        )}
                      </div>
                    </div>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredData.map((software, index) => (
                <TableRow key={software.id}>
                  <TableCell style={{ width: columnWidths.name }} sx={{ fontSize: '16px' }}>
                    {software.name}
                  </TableCell>
                  <TableCell style={{ width: columnWidths.version }} sx={{ fontSize: '16px' }}>
                    {software.version}
                  </TableCell>
                  <TableCell style={{ width: columnWidths.source }} sx={{ fontSize: '16px' }}>
                    {software.source}
                  </TableCell>
                  <TableCell style={{ width: columnWidths.vendor }} sx={{ fontSize: '16px' }}>
                    {software.vendor}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
}

function OSInfo({ data }) {
  return (
    <div className="server-info">
      <div className="server-info-container">
        <span className="server-info-title">Software Specifications - Platform & OS Information</span>
        <div className="server-info-content">
          <div className="server-info-column">
            {[
              { label: 'Platform', value: data.platform },
              { label: 'Operating System Name', value: data.operating_systems[0].name },
              { label: 'OS Version', value: data.operating_systems[0].version },
              { label: 'Architecture', value: data.operating_systems[0].arch },
              { label: 'Kernel Version', value: data.operating_systems[0].kernel_version },
            ].map((item, index) => (
              <div key={index} className="server-info-item">
                <p className="server-info-label">{item.label}</p>
                <input
                  type="text"
                  value={item.value}
                  disabled
                  className="server-info-input"
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

function WindowsUpdates({ data }) {
  const [sortState2, setSortState2] = useState({ column: null, order: null });
  const headers = ['Update ID', 'Host ID', 'Date', 'KB ID'];

  const handleSort = (index, direction) => {
    setSortState2({
      column: index,
      order: direction,
    });

    const tbody = document.querySelector('tbody');
    const rows = Array.from(tbody.querySelectorAll('tr'));

    const rowPairs = [];
    for (let i = 0; i < rows.length; i++) {
      const parentRow = rows[i];
      const nextRow = rows[i + 1];
      if (nextRow && nextRow.querySelector('td[colspan]')) {
        rowPairs.push([parentRow, nextRow]);
        i++;
      } else {
        rowPairs.push([parentRow]);
      }
    }

    const sortedPairs = rowPairs.sort((a, b) => {
      const valueA = a[0].children[index].innerText.toLowerCase();
      const valueB = b[0].children[index].innerText.toLowerCase();

      const dateA = parseDate(valueA);
      const dateB = parseDate(valueB);

      if (dateA && dateB) {
        // Both values are date-time strings, compare as dates
        return direction === "asc" ? dateA - dateB : dateB - dateA;
      }
      
      const numA = parseFloat(valueA);
      const numB = parseFloat(valueB);

      if (!isNaN(numA) && !isNaN(numB)) {
        return direction === "asc" ? numA - numB : numB - numA;
      }

      if (valueA < valueB) return direction === "asc" ? -1 : 1;
      if (valueA > valueB) return direction === "asc" ? 1 : -1;
      return 0;
    });

    while (tbody.firstChild) {
      tbody.removeChild(tbody.firstChild);
    }

    sortedPairs.forEach(pair => {
      pair.forEach(row => tbody.appendChild(row));
    });
  };

  const parseDate = (dateString) => {
    const regex = /^(\d{2})\/(\d{2})\/(\d{4}),\s(\d{2}):(\d{2}):(\d{2})$/;
    const match = dateString.match(regex);

    if (match) {
        const day = match[1];
        const month = match[2] - 1; 
        const year = match[3];
        const hours = match[4];
        const minutes = match[5];
        const seconds = match[6];
        return new Date(year, month, day, hours, minutes, seconds);
    }
    return null;
  };

  return (
    <div style={{ padding: "10px" }}>
      <div style={{ width: "fit-content", padding: "0 20px" }}>
        <span style={{ marginBottom: "10px", fontSize: "25px" }}>Software Specifications - Windows Updates</span>
        <TableContainer component={Paper} sx={{ maxHeight: '55vh', overflow: 'auto', marginTop: '10px', width: '100%' }}>
          <Table stickyHeader aria-label="Windows Updates Table" sx={{ fontSize: '16px', width: '100%' }}>
            <TableHead>
              <TableRow>
                {headers.map((header, index) => (
                  <TableCell key={index} style={{ width: '400px' }} sx={{ fontSize: '16px' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      {header}
                      <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '10px' }}>
                        {sortState2.column === index && sortState2.order === 'asc' ? (
                          <KeyboardArrowDownIcon
                            style={{ cursor: 'pointer', fontSize: '24px', fontWeight: "bold" }}
                            onClick={() => handleSort(index, "desc")}
                          />
                        ) : (
                          sortState2.column !== index && (
                            <KeyboardArrowUpIcon
                              style={{ cursor: 'pointer', fontSize: '16px', fontWeight: "bold" }}
                              onClick={() => handleSort(index, "asc")}
                            />
                          )
                        )}
                        {sortState2.column === index && sortState2.order === "desc" ? (
                          <KeyboardArrowUpIcon
                            style={{ cursor: "pointer", fontSize: "24px", fontWeight: "bold" }}
                            onClick={() => handleSort(index, "asc")}
                          />
                        ) : (
                          sortState2.column !== index && (
                            <KeyboardArrowDownIcon
                              style={{ cursor: "pointer", fontSize: "16px", fontWeight: "bold" }}
                              onClick={() => handleSort(index, "desc")}
                            />
                          )
                        )}
                      </div>
                    </div>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((update, index) => (
                <TableRow key={index}>
                  <TableCell sx={{ fontSize: '16px' }}>{update.id}</TableCell>
                  <TableCell sx={{ fontSize: '16px' }}>{update.host_id}</TableCell>
                  <TableCell sx={{ fontSize: '16px' }}>{new Date(update.date_epoch * 1000).toLocaleString()}</TableCell>
                  <TableCell sx={{ fontSize: '16px' }}>{update.kb_id}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
}

const ServerSpecifications = () => {
  document.title = 'MSA - Server Specifications';
  const query = useQuery();
  // const selectedserver = query.get('servername');
  // console.log(selectedserver);
  const initialServer = query.get('servername');
  const [selectedServers, setSelectedServers] = useState([initialServer]);
  const [activeServer, setActiveServer] = useState(initialServer);
  const [content, setContent] = useState("General Information");
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]); 
  const [activeMenuItem, setActiveMenuItem] = useState("General Information");
  const [hasWindowsUpdates, setHasWindowsUpdates] = useState(false);

  const { fetchWithTimeout } = useContext(TableContext);
  
  useEffect(() => {
    const handleMessage = (event) => {
      if (event.data && event.data.serverName) {
        setSelectedServers((prevServers) => {
          if (!prevServers.includes(event.data.serverName)) {
            return [...prevServers, event.data.serverName];
          }
          return prevServers;
        });
        setActiveServer(event.data.serverName);
      }
    };

    window.addEventListener('message', handleMessage);

    const handleBeforeUnload = () => {
      localStorage.removeItem('serverTabId');
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('message', handleMessage);
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    if (activeServer) {
      setLoading(true);
      fetchWithTimeout(
        `${process.env.REACT_APP_BACKEND_URI}/getosquerydata?servername=${encodeURIComponent(
          activeServer
        )}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
        .then((res) => res.json())
        .then((data) => {
          setData(data);
          setLoading(false);
          setHasWindowsUpdates(data.software.windows_updates && data.software.windows_updates.length > 0);
        });
    }
  }, [activeServer]);

  const handleCloseTab = (server) => {
    setSelectedServers((prevServers) => {
      const newServers = prevServers.filter((s) => s !== server);
      if (newServers.length === 0) {
        window.close();
      } else if (server === activeServer) {
        const currentIndex = prevServers.indexOf(server);
        const nextIndex = (currentIndex + 1) % newServers.length;
        setActiveServer(newServers[nextIndex]);
      }
      return newServers;
    });
  };

  const renderContent = () => {
    if (loading) {
      return (
        <div style={{ width: '78vw', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '60vh' }}>
          <div className="spinner"></div>
          <br></br>
          <p>Loading Server Specifications...</p>
        </div>
      );
    }

    const no_details = <div style={{ marginLeft: '20px', padding: '10px', fontSize: "20px" }}>No Data Available for this Server</div>;    
    switch (content) {
      case "General Information":
        return data.general ? <GeneralInfo data={data.general}></GeneralInfo> : no_details;
      case "Hardware Specs":
        return data.hardware ? <HardwareSpecs data={data.hardware}></HardwareSpecs> : no_details;
      case "Disk Info":
        return data.hardware ? <DiskInfo diskData={data.hardware}></DiskInfo> : no_details;
      case "OS Info":
        return data.software ? <OSInfo data={data.software}></OSInfo> : no_details;
      case "Installed Software":
        return data.software ? <InstalledSoftware softwareData={data.software}></InstalledSoftware> : no_details;
      case "Windows Updates":
        return hasWindowsUpdates ? <WindowsUpdates data={data.software.windows_updates}></WindowsUpdates> : handleMenuItemClick("Installed Software");
      default:
        return <div />;
    }
  };

  const handleMenuItemClick = (item) => {
    setContent(item);
    setActiveMenuItem(item);
  };

  return (
      <div>
        <nav className="navbar" style={{ backgroundColor: 'white', height: '65px', boxShadow: 'none', borderBottom: '1px solid lightgrey' }}>
            <div className="nav-bar" style={{ backgroundColor: 'white' }}>
            <img
                src="./images/Maersk_Logo_RGB.svg"
                alt=" "
                height="120"
                width="240"
            />
            <div className="label" style={{ fontWeight: '500', marginLeft: '5px', fontSize: '22px', cursor: 'default' }}>
                    Server Specifications
            </div>
            </div>
        </nav>
        <div
            style={{
                display: "flex",
                minHeight: "100%",
                marginTop: "65px",
                position: "fixed",
                overflowY: "auto",
            }}
        >
          <Sidebar
              closeOnClick={true}
              toggled={true}
              rootStyles={{
                width: '300px',
                // backgroundColor: "rgb(66, 176, 213)",
              }}
          >
            <Menu>
              <div style={{ padding: '20px', fontWeight: '10', fontSize: '22px', cursor: 'default' }}>Specification Fields</div>
              <MenuItem
                onClick={() => handleMenuItemClick("General Information")}
                style={{
                  backgroundColor: activeMenuItem === "General Information" ? 'rgb(66, 176, 213)' : 'white',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                General Information
                {activeMenuItem === "General Information"}
              </MenuItem>
              <SubMenu
                label={
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    Hardware Specifications
                  </div>
                }
              >
                <MenuItem 
                  onClick={() => handleMenuItemClick("Hardware Specs")}
                  style={{
                    backgroundColor: activeMenuItem === "Hardware Specs" ? 'rgb(66, 176, 213)' : 'white',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  System Configuration
                </MenuItem>
                <MenuItem 
                  onClick={() => handleMenuItemClick("Disk Info")}
                  style={{
                    backgroundColor: activeMenuItem === "Disk Info" ? 'rgb(66, 176, 213)' : 'white',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  Disk Information
                </MenuItem>
              </SubMenu>
              <SubMenu
                label={
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    Software Specifications
                  </div>
                }
                // style={{
                //   backgroundColor: activeMenuItem === "OS Info" ? 'white' : 'lightgrey',
                // }}
              >
                <MenuItem 
                  onClick={() => handleMenuItemClick("OS Info")}
                  style={{
                    backgroundColor: activeMenuItem === "OS Info" ? 'rgb(66, 176, 213)' : 'white',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  Platform & OS Information
                </MenuItem>
                <MenuItem 
                  onClick={() => handleMenuItemClick("Installed Software")}
                  style={{
                    backgroundColor: activeMenuItem === "Installed Software" ? 'rgb(66, 176, 213)' : 'white',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  Installed Software
                </MenuItem>
                {hasWindowsUpdates && <MenuItem 
                  onClick={() => handleMenuItemClick("Windows Updates")}
                  style={{
                    backgroundColor: activeMenuItem === "Windows Updates" ? 'rgb(66, 176, 213)' : 'white',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  Windows Updates
                </MenuItem>}
              </SubMenu>
            </Menu>
          </Sidebar>
          <div>
            <div className="server-tabs" style={{ padding: '10px', marginLeft: '10px', marginBottom: '5px' }}>
              {selectedServers.map((server, index) => (
                  <button
                    key={index}
                    className={`server-tab ${server === activeServer ? 'active' : ''}`}
                    onClick={() => setActiveServer(server)}
                    style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
                  >
                    <span style={{ marginRight: '5px' }}>{server}</span>
                    <FaTimes
                      style={{ cursor: 'pointer' }}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleCloseTab(server);
                      }}
                    />
                  </button>
              ))}
            </div>
            <div>
              {renderContent()}
            </div>
          </div>
        </div>
        <Footer />
      </div>
  );
};

export default ServerSpecifications;