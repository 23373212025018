import React, { useContext } from "react";
import { FaTimes } from "react-icons/fa";
import { useState } from "react";
import { toast } from "react-toastify";
import Papa from "papaparse";
import { saveAs } from "file-saver";
import "./BulkUploadModal.css"; // Assuming you have a CSS file for styles
import Table from "./Table";
import { TableContext } from "../context";
import CommonModal from "./CommonModal";
import StatusTable from "./StatusTable";

/**
 * BulkUploadModal component allows users to upload CSV files for bulk data processing.
 * 
 * @component
 * @param {Object} props - The component props.
 * @param {boolean} props.isOpen - Determines if the modal is open.
 * @param {function} props.onClose - Function to close the modal.
 * @param {function} props.setOpen - Function to set the modal open state.
 * 
 * @returns {JSX.Element|null} The rendered component or null if the modal is not open.
 * 
 * @example
 * <BulkUploadModal isOpen={isOpen} onClose={handleClose} setOpen={setOpen} />
 */
function BulkUploadModal({ isOpen, onClose, setOpen }) {
  const [selectedFile, setSelectedFile] = useState(null);
  const { graphData, table1, setshowCommit } = useContext(TableContext);
  const [uploadStatus, setUploadStatus] = useState("");
  const [response, setResponse] = useState(null);
  const [showstatus, setshowstatus] = useState(false);

  if (!isOpen) return null;

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
      console.log("file", file);
      setUploadStatus("");
    }
  };

  const handleDownloadClick = () => {
    const headers = ["Application Name", "Server Name"];
    const csv = Papa.unparse({
      fields: headers,
      data: [],
    });
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    saveAs(blob, "Upload Format.csv");
  };

  const handleUpload = async () => {
    if (!selectedFile) {
      toast.error("Please select a file to upload.");
      return;
    }

    await toast.promise(
      new Promise((resolve, reject) => {
        Papa.parse(selectedFile, {
          complete: async (results) => {
            const filteredData = results.data;
            // const filteredData = results.data.filter((row) => {
            //   const [applicationName, serverName] = row;
            //   console.log(applicationName, serverName);
            //   return !table1?.some(
            //     (value) =>
            //       value[0] == applicationName && value[2] === serverName
            //   );
            // });
            console.log(filteredData);

            const formData = new FormData();
            formData.append(
              "file",
              new Blob([Papa.unparse(filteredData)], { type: "text/csv" })
            );
            formData.append("requestedby", graphData?.displayName);

            try {
              const response = await fetch(`${process.env.REACT_APP_BACKEND_URI}/bulkCreate`, {
                method: "POST",
                headers: {
                  Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
                body: formData,
              });

              if (response.ok) {
                const responseData = await response.json(); // Read the response body once
                console.log(responseData.data);
                await setResponse(responseData.data);
                setUploadStatus("Upload success");
                setshowstatus(true);
                setUploadStatus("");

                if (responseData.message == "File accepted") {
                }
                resolve();
              } else {
                setUploadStatus("Upload failed");
                toast.error("File upload failed.");
                reject(new Error("File upload failed."));
              }
            } catch (error) {
              setUploadStatus("Upload failed");
              toast.error("File upload failed.");
              console.error(error);
              reject(error);
            }
          },
        });
      }),
      {
        pending: "Uploading...",
        success: "File uploaded successfully!",
      }
    );
  };

  return (
    <div
      className="modal-overlay"
      onClick={() => {
        onClose();
        setshowstatus(false);
      }}
    >
      <div
        className="modal-content"
        onClick={(e) => {
          e.stopPropagation();
          // setshowstatus(false);
        }}
      >
        <FaTimes
          className="close-icon"
          onClick={() => {
            setOpen(false);
            setUploadStatus("");
            setshowstatus(false);
          }}
        />
        {!showstatus ? (
          <>
            <h2>Upload CSV File</h2>
            <p>
              Kindly upload a properly formatted CSV{" "}
              <a onClick={handleDownloadClick} className="download-link">
                (download here)
              </a>
              , ensuring no cells are merged and data is entered sequentially by
              row.
            </p>
            <p>
              <strong>Note:</strong> The bulk upload feature is only available
              for app to server and server to app relationships.
              It is recommended to upload 50 records at a time.
            </p>
            <input
              type="file"
              accept=".csv"
              onChange={handleFileChange}
              className="file-input"
            />
            <button
              onClick={handleUpload}
              className="upload-button"
              style={{ backgroundColor: "#42B0D5" }}
            >
              Upload
            </button>
            {uploadStatus && (
              <p
                className={`status-message ${
                  uploadStatus === "Upload failed" ? "error" : ""
                }`}
              >
                {uploadStatus}
              </p>
            )}
          </>
        ) : (
          <StatusTable rows={response} key="Bulk Upload" applicationName="Placeholder"/>
        )}
      </div>
    </div>
  );
}

export default BulkUploadModal;
