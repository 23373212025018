import React, { useState, useContext, useRef, useEffect } from "react";
import { TableContext } from "../context";
import "../App.css";
import { Button } from "@anchor/react-components";
import { ToastContainer, toast } from "react-toastify";

/**
 * Table2 component renders a table with filtering options and handles various actions like delete, discard, and confirm.
 *
 * @component
 * @param {Object} props - The properties object.
 * @param {Array} props.table1 - The data to be displayed in the table.
 * @param {number} props.FilterIndex - The index of the first filter column.
 * @param {number} props.FilterIndex2 - The index of the second filter column.
 * @param {string} props.Header1 - The header for the first column.
 * @param {string} props.Header2 - The header for the second column.
 * @param {string} props.Header3 - The header for the third column.
 * @param {string} props.Header4 - The header for the fourth column.
 * @param {string} props.Header5 - The header for the fifth column.
 * @param {string} props.Header6 - The header for the sixth column.
 * @param {string} props.Header7 - The header for the seventh column.
 * @param {Function} props.getData - Function to fetch the latest data.
 *
 * @returns {JSX.Element} The rendered Table2 component.
 */
export default function Table2(props) {
  const table1 = props.table1;
  const heightRef = useRef();
  const [height, setHeight] = useState();
  const { graphData, fetchData, fetchWithTimeout } = useContext(TableContext);

  const uniqueValues1 = [
    ...new Set(table1.map((row) => row[props.FilterIndex])),
  ];
  
  
  const [uniqueValues2, setUniqueValues2] = useState([...new Set(table1.map((row) => row[props.FilterIndex2]))]);
  const [selectedFilter1, setSelectedFilter1] = useState("All");
  const [selectedFilter2, setSelectedFilter2] = useState("All");
  

  useEffect(() => {
    const filterTable = () => {
      const table = document.getElementById("data-table");
      const tbody = table?.querySelector("tbody");
      const rows = tbody?.querySelectorAll("tr");

      rows.forEach((row) => {
        const cell1 = row.querySelector(
          `td:nth-child(${props.FilterIndex + 1})`
        );
        const cell2 = row.querySelector(
          `td:nth-child(${props.FilterIndex2 + 2})`
        );
        console.log(selectedFilter1,selectedFilter2)
        const matchFilter1 =
          selectedFilter1 === "All" || cell1.textContent === selectedFilter1;
        const matchFilter2 =
          selectedFilter2 === "All" || cell2.textContent === selectedFilter2;

        if (matchFilter1 && matchFilter2) {
          row.style.display = "";
        } else {
          row.style.display = "none";
        }
      });
    };

    filterTable();
  }, [selectedFilter1, selectedFilter2, props.FilterIndex, props.FilterIndex2]);
  useEffect(() => {
    const filteredTable1 = selectedFilter1 === "All" ? table1 : table1.filter((row) => row[props.FilterIndex] === selectedFilter1);
    setUniqueValues2([...new Set(filteredTable1.map((row) => row[props.FilterIndex2]))]);
    const allRadioButton2 = document.querySelector('input[name="filter2"][value="All"]');
    if (allRadioButton2) {
      allRadioButton2.checked = true;
    }
    setSelectedFilter2("All");
  }, [selectedFilter1]);

  /**
   * Sends a DELETE request to the server to delete a specified resource.
   *
   * @param {Object} value - The data to be sent in the body of the request.
   */
  const handleDelete = (value) => {
    fetchWithTimeout(`${process.env.REACT_APP_BACKEND_URI}/delete`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify(value),
    });
  };

  /**
   * Handles the discard click event by sending a POST request to the server.
   * Displays a success or error toast notification based on the response.
   * 
   * @async
   * @function handleDiscardClick
   * @param {any} value - The value to be discarded.
   * @returns {Promise<void>}
   * @throws Will throw an error if the request times out or if there is a server error.
   */
  const handleDiscardClick = async (value) => {
    console.log(value);
    try {
      const response = await fetchWithTimeout(
        `${process.env.REACT_APP_BACKEND_URI}/rediscard`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify({
            value: value,
            requested_by: graphData.displayName,
          }),
        }
      );
      const data = await response.json();
      if (data?.includes("Success")) {
        toast.success("Discarded Successfully", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
      props.getData();
      await fetchData();
    } catch (error) {
      if (error.message === "Timeout") {
        toast.error("Request Timed Out", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        toast.error("Server Error", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    }
  };
  /**
   * Handles the click event for confirming a value.
   * @param {any} value - The value to be confirmed.
   * @returns {Promise<void>} - A promise that resolves when the confirmation is complete.
   */
  const handleConfirmClick = async (value) => {
    console.log(value);
    try {
      const response = await fetchWithTimeout(
        `${process.env.REACT_APP_BACKEND_URI}/reconfirm`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify({
            value: value,
            requested_by: graphData.displayName,
          }),
        }
      );
      const data = await response.json();
      if (data?.includes("Success")) {
        toast.success("Confirmed Successfully", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
      props.getData();
      await fetchData();
    } catch (error) {
      if (error.message === "Timeout") {
        toast.error("Request Timed Out", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        toast.error("Server Error", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    }
  };

  return (
    <div>
      <div
        style={{
          margin: "auto",
          width: "95%",
          marginTop: "70px",
          borderBottom: "2px solid #87CEEB",
          borderLeft: "2px solid #87CEEB",
          maxWidth: "fit-content",
          minHeight: "fit-content",
        }}
      >
        <div
          id="filter"
          style={{
            float: "left",
            border: "2px solid #87CEEB",
            width: "200px",
            borderBottom: "none",
            borderLeft: "none",
            paddingTop: "7px",
            borderRight: "none",
            maxHeight: "100%", // Set a maximum height for the filter container
             // Add vertical scrollbar if content exceeds max height
          }}
           >
          <div style={{ borderBottom: "2px solid #87CEEB" }}>
            <label htmlFor="ciTypeFilter" style={{ margin: "4px" }}>
              Filter By CI Type:
            </label>
          </div>
          <div className="filters" style={{ padding: "7px",overflowY: "auto",height: "130px" }}>
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                value="All"
                name="filter1"
                id="All1"
                onChange={() => setSelectedFilter1("All")}
              />
              <label
                className="form-check-label"
                htmlFor="All1"
                style={{ fontSize: "16px" }}
              >
                All
              </label>
            </div>
            {uniqueValues1.map((value, key) => (
              <div className="form-check" key={key}>
                <input
                  className="form-check-input"
                  type="radio"
                  value={value}
                  id={value}
                  name="filter1"
                  onChange={() => setSelectedFilter1(value)}
                />
                <label
                  className="form-check-label"
                  htmlFor={value}
                  style={{ fontSize: "16px" }}
                >
                  {value}
                </label>
              </div>
            ))}
          </div>
          <div style={{ borderBottom: "2px solid #87CEEB", marginTop: "10px" }}>
            <label htmlFor="ciTypeFilter2" style={{ margin: "4px" }}>
              Filter By Category:
            </label>
          </div>
          <div className="filters" style={{ padding: "7px",overflowY: "auto",height: "127px", }}>
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                value="All"
                name="filter2"
                id="All2"
                onChange={() => setSelectedFilter2("All")}
              />
              <label
                className="form-check-label"
                htmlFor="All2"
                style={{ fontSize: "16px" }}
              >
                All
              </label>
            </div>
            {uniqueValues2.map((value, key) => (
              <div className="form-check" key={key}>
                <input
                  className="form-check-input"
                  type="radio"
                  value={value}
                  id={value}
                  name="filter2"
                  onChange={() => setSelectedFilter2(value)}
                />
                <label
                  className="form-check-label"
                  htmlFor={value}
                  style={{ fontSize: "16px" }}
                >
                  {value}
                </label>
              </div>
            ))}
          </div>
        </div>
        <div
        className="table-container"
          // style={{
          //   marginLeft: "20px",
          //   overflowY: "auto",
          //   tableLayout: "fixed",
          //   borderCollapse: "collapse",
          //   minHeight: "200px",
          //   maxHeight: "fit-content",
          //   maxWidth: "fit-content",
          // }}
        >
          <table id="data-table" className="custom-table" ref={heightRef}>
            <thead>
              <tr>
                <th style={{ border: "2px solid #87CEEB" }}>{props.Header1}</th>
                <th style={{ border: "2px solid #87CEEB" }}>{props.Header2}</th>
                <th style={{ border: "2px solid #87CEEB" }}>{props.Header8}</th>
                <th style={{ border: "2px solid #87CEEB" }}>{props.Header3}</th>
                <th style={{ border: "2px solid #87CEEB" }}>{props.Header4}</th>
                <th style={{ border: "2px solid #87CEEB" }}>{props.Header5}</th>
                <th style={{ border: "2px solid #87CEEB" }}>{props.Header6}</th>
                <th style={{ border: "2px solid #87CEEB",width:"11vw" }}>{props.Header7}</th>
              </tr>
            </thead>
            <tbody>
              {table1.map((value, key) => {
                return (
                  <tr key={key}>
                    <td style={{ border: "2px solid #87CEEB" }}>{value[0]}</td>
                    <td style={{ border: "2px solid #87CEEB" }}>{value[1]}</td>
                    <td style={{ border: "2px solid #87CEEB" }}>{value[7]}</td>
                    <td style={{ border: "2px solid #87CEEB" }}>{value[2]}</td>
                    <td style={{ border: "2px solid #87CEEB" }}>{value[3]}</td>
                    <td style={{ border: "2px solid #87CEEB" }}>{value[4]}</td>
                    <td style={{ border: "2px solid #87CEEB" }}>
                      {value[5]}
                      &nbsp;
                      {value[5] == "False" && (
                        <div style={{ display: "inline-flex" }}>
                          <Button
                            appearance="alt"
                            fit="small"
                            id="primary"
                            justifyItems="center"
                            label="Confirm"
                            name="primary"
                            onClick={function noRefCheck() {
                              handleConfirmClick(value);
                            }}
                            title="Button"
                            variant="filled"
                            style={{ fontSize: "11px" }}
                          />
                          &nbsp;
                          <Button
                            appearance="error"
                            fit="small"
                            id="primary"
                            justifyItems="center"
                            label="Discard"
                            name="primary"
                            width="auto"
                            onClick={function noRefCheck() {
                              handleDiscardClick(value);
                            }}
                            title="Button"
                            variant="filled"
                            style={{ fontSize: "11px" }}
                          />
                        </div>
                      )}
                    </td>
                    <td style={{ border: "2px solid #87CEEB",width:"11vw" }}>{value[6]}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      {/* <ToastContainer /> */}
    </div>
  );
}
