import { TableContext } from "../context";
import React from "react";
import "../App.css";
import { saveAs } from "file-saver";
import Papa from "papaparse";
import { CiCircleInfo } from "react-icons/ci";
import { IoIosMore } from "react-icons/io";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'; 
//  import '../helper.js';
import DeleteConfirmation from "./DeleteConfirmation";
import {
  useRef,
  useLayoutEffect,
  useContext,
  useState,
  useEffect,
} from "react";
import { RxQuestionMarkCircled } from "react-icons/rx";

import { IoMdDownload } from "react-icons/io";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Icon } from "@anchor/react-components";
import { Tooltip } from "react-tooltip";
/**
 * Table component that displays data in a tabular format with filtering and download functionality.
 *
 * @component
 * @param {Object} props - The properties object.
 * @param {Array} props.table1 - The primary table data.
 * @param {Function} props.setTable1 - Function to set the primary table data.
 * @param {Array} props.deletedRows - Array of deleted rows.
 * @param {Function} props.setDeletedRows - Function to set the deleted rows.
 * @param {Object} props.graphData - Data related to the graph.
 * @param {Array} props.data - The data to be displayed in the table.
 * @param {number} props.tableRightBoundary - The right boundary of the table.
 * @param {Function} props.setTableRightBoundary - Function to set the right boundary of the table.
 * @param {number} props.tableBottomtBoundary - The bottom boundary of the table.
 * @param {Function} props.setTableBottomBoundary - Function to set the bottom boundary of the table.
 * @param {Function} props.fetchWithTimeout - Function to fetch data with a timeout.
 * @param {string} props.applicationName - The name of the application.
 * @param {boolean} props.applicationManagerDisplay - Flag to display the application manager.
 * @param {Function} props.setApplicationManagerDisplay - Function to set the application manager display flag.
 * @param {Array} props.retiredApps - Array of retired applications.
 * @param {string} props.token - The authentication token.
 * @param {number} props.FilterIndex - The index for the first filter.
 * @param {number} props.FilterIndex2 - The index for the second filter.
 * @param {string} props.Header1 - The header for the first column.
 * @param {string} props.Header2 - The header for the second column.
 * @param {string} props.Header3 - The header for the third column.
 * @param {string} props.Header4 - The header for the fourth column.
 * @param {string} props.Header5 - The header for the fifth column.
 * @param {string} props.Header6 - The header for the sixth column.
 * @param {string} props.Header7 - The header for the seventh column.
 * @param {string} props.Header8 - The header for the eighth column.
 * @param {string} props.applicationId - The ID of the application.
 * @param {string} props.applicationOwner - The owner of the application.
 * @param {Function} props.setshowCommit - Function to set the show commit flag.
 */
export default function Table(props) {
  const {
    table1,
    setTable1,
    deletedRows,
    setDeletedRows,
    graphData,
    data,
    tableRightBoundary,
    setTableRightBoundary,
    tableBottomtBoundary,
    setTableBottomBoundary,
    fetchWithTimeout,
    applicationName,
    applicationManagerDisplay,
    setApplicationManagerDisplay,
    retiredApps,
    token,
  } = useContext(TableContext);
  const heightRef = useRef();
  const uniqueValuesSet = new Set([
    ...(Array.isArray(table1)
      ? table1?.map((row) => row[props.FilterIndex])
      : []),
    ...(Array.isArray(data)
      ? data
          .filter((innerList) => innerList[5] === "create")
          .map((row) => row[3])
      : []),
  ]);

  const [uniqueValues2, setUniqueValues2] = useState();
  const [uniqueValues3, setUniqueValues3] = useState();
  const [selectedserver, setSelectedServer] = useState("");

  const [selectedFilter1, setSelectedFilter1] = useState("All");
  const [selectedFilter2, setSelectedFilter2] = useState("All");
  const [selectedFilter3, setSelectedFilter3] = useState("All");
  const [rowCount, setRowCount] = useState(0);
  const tableBodyRef = useRef(null);

  const [sortState1, setSortState1] = useState({ columnIndex: null, order: null });
  const handleSort = (colIndex, direction) => {
    setSortState1({
      columnIndex: colIndex,
      order: direction,
    });
  
    const tbody = document.querySelector('tbody');
    const rows = Array.from(tbody.querySelectorAll('tr'));
  
    const sortedRows = rows.sort((a, b) => {
      const valueA = a.children[colIndex].innerText.toLowerCase();
      const valueB = b.children[colIndex].innerText.toLowerCase();
  
      // Handle sorting for numerical strings, mixed-case strings, or other complex formats
      // If both values are numbers, sort numerically
      const numA = parseFloat(valueA);
      const numB = parseFloat(valueB);
      
      if (!isNaN(numA) && !isNaN(numB)) {
        return direction === "asc" ? numA - numB : numB - numA;
      }
  
      // String comparison (case-insensitive alphabetical sorting)
      if (valueA < valueB) return direction === "asc" ? -1 : 1;
      if (valueA > valueB) return direction === "asc" ? 1 : -1;
      return 0; // if values are equal
    });
  
    // Clear the existing rows
    while (tbody.firstChild) {
      tbody.removeChild(tbody.firstChild);
    }
  
    // Append the sorted rows
    sortedRows.forEach(row => tbody.appendChild(row));
  };

  const uniqueValues = Array.from(uniqueValuesSet);
  useEffect(() => {
    const uniqueValuesSet2 = new Set([
      ...(Array.isArray(table1)
        ? table1?.map((row) => row[props.FilterIndex2])
        : []),
      ...(Array.isArray(data)
        ? data
            .filter((innerList) => innerList[5] === "create")
            .map((row) => row[9])
        : []),
    ]);
    setUniqueValues2(
      Array.from(uniqueValuesSet2).filter(
        (value) => value !== null && value.trim() !== ""
      )
    );
    if (tableBodyRef.current) {
      const visibleRows = Array.from(
        tableBodyRef.current.getElementsByTagName("tr")
      ).filter((row) => window.getComputedStyle(row).display !== "none");
      setRowCount(visibleRows.length);
    }
  }, [table1, data]);

  useEffect(() => {
    const allRadioButton2 = document.querySelector(
      'input[name="filter"][value="All"]'
    );
    if (allRadioButton2) {
      allRadioButton2.checked = true;
    }
    setSelectedFilter1("All");
  }, [data]);

  useEffect(() => {
    const uniqueValuesSet2 = new Set([
      ...(Array.isArray(table1) ? table1?.map((row) => row[0]) : []),
      ...(Array.isArray(data)
        ? data
            .filter((innerList) => innerList[5] === "create")
            .map((row) => row[0])
        : []),
    ]);
    setUniqueValues3(
      Array.from(uniqueValuesSet2).filter(
        (value) => value !== null && value.trim() !== ""
      )
    );
    if (tableBodyRef.current) {
      const visibleRows = Array.from(
        tableBodyRef.current.getElementsByTagName("tr")
      ).filter((row) => window.getComputedStyle(row).display !== "none");
      setRowCount(visibleRows.length);
    }
  }, [table1, data]);

  const handleDownloadClick = () => {
    const headers = [
      props.Header1,
      props.Header2,
      props.Header3,
      props.Header4,
      props.Header5,
      props.Header6,
      props.Header7,
      props.Header8,
    ];
    // Assuming table1 is available in the scope and headers are passed as props
    const csv = Papa.unparse({
      fields: headers,
      data: table1,
    });
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    saveAs(blob, "App to Server.csv");
  };

  useEffect(() => {
    const filterTable = () => {
      const table = document.getElementById("data-table");
      const tbody = table?.querySelector("tbody");
      const rows = tbody?.querySelectorAll("tr");

      rows.forEach((row) => {
        const cell1 = row.querySelector(
          `td:nth-child(${props.FilterIndex + 1})`
        );
        console.log(cell1);
        const cell2 = row.querySelector(
          `td:nth-child(${props.FilterIndex2 + 1})`
        );
        const cell3 = row.querySelector(`td:nth-child(${0 + 1})`);
        // console.log(selectedFilter1, selectedFilter2);
        console.log(cell1.textContent, selectedFilter1);
        const condition1 = selectedFilter1 === "All";
        const condition2 = cell1.textContent == selectedFilter1;
        console.log(condition1, condition2);
        const matchFilter1 =
          selectedFilter1 === "All" || cell1.textContent === selectedFilter1;
        // console.log(matchFilter1);
        console.log(cell1.textContent.length, selectedFilter1.length);

        const matchFilter2 =
          selectedFilter2 == "All" || cell2.textContent == selectedFilter2;
        const matchFilter3 =
          selectedFilter3 == "All" || cell3.textContent == selectedFilter3;

        if (matchFilter1 && matchFilter2 && matchFilter3) {
          // console.log(matchFilter1, matchFilter2);
          row.style.display = "";
        } else {
          row.style.display = "none";
        }
      });
      if (tableBodyRef.current) {
        const visibleRows = Array.from(
          tableBodyRef.current.getElementsByTagName("tr")
        ).filter((row) => window.getComputedStyle(row).display !== "none");
        setRowCount(visibleRows.length);
      }
    };

    filterTable();
  }, [selectedFilter1, selectedFilter2, selectedFilter3]); 
  useEffect(() => {
    const filteredTable1 =
      selectedFilter1 === "All"
        ? table1
        : table1?.filter((row) => row[props.FilterIndex] === selectedFilter1);
    // setUniqueValues2([...new Set(filteredtable1?.filter(value => value !== null && value !== '').map((row) => row[props.FilterIndex2]))]);
    const filteredData =
      selectedFilter1 === "All"
        ? data
        : data.filter((row) => row[3] === selectedFilter1);
    setUniqueValues2([
      ...new Set(
        filteredTable1
          .map((row) => row[props.FilterIndex2])
          .filter((value) => value && value.trim() !== "")
      ),
      ...new Set(
        filteredData
          .map((row) => row[9])
          .filter((value) => value && value.trim() !== "")
      ),
    ]);
    const allRadioButton2 = document.querySelector(
      'input[name="filter2"][value="All"]'
    );
    if (allRadioButton2) {
      allRadioButton2.checked = true;
    }
    setSelectedFilter2("All");

    const filteredTable2 =
      selectedFilter2 === "All"
        ? filteredTable1
        : filteredTable1?.filter(
            (row) => row[props.FilterIndex2] === selectedFilter2
          );

    setUniqueValues3([
      ...new Set(
        filteredTable2
          .map((row) => row[0])
          .filter((value) => value && value.trim() !== "")
      ),
    ]);

    const allRadioButton3 = document.querySelector(
      'input[name="filter3"][value="All"]'
    );
    if (allRadioButton3) {
      allRadioButton3.checked = true;
    }
    setSelectedFilter3("All");
  }, [selectedFilter1]);
  //   console.log(uniqueValues)

  useEffect(() => {
    const filteredTable1 =
      selectedFilter1 === "All"
        ? table1
        : table1?.filter((row) => row[props.FilterIndex] === selectedFilter1);
    // setUniqueValues2([...new Set(filteredtable1?.filter(value => value !== null && value !== '').map((row) => row[props.FilterIndex2]))]);
    // const filteredData =
    //   selectedFilter1 === "All"
    //     ? data
    //     : data.filter((row) => row[3] === selectedFilter1);
    // setUniqueValues2([
    //   ...new Set(
    //     filteredTable1
    //       .map((row) => row[props.FilterIndex2])
    //       .filter((value) => value && value.trim() !== "")
    //   ),
    //   ...new Set(
    //     filteredData
    //       .map((row) => row[9])
    //       .filter((value) => value && value.trim() !== "")
    //   ),
    // ]);

    const filteredTable2 =
      selectedFilter2 === "All"
        ? filteredTable1
        : filteredTable1?.filter(
            (row) => row[props.FilterIndex2] === selectedFilter2
          );

    setUniqueValues3([
      ...new Set(
        filteredTable2
          .map((row) => row[0])
          .filter((value) => value && value.trim() !== "")
      ),
    ]);

    const allRadioButton3 = document.querySelector(
      'input[name="filter3"][value="All"]'
    );
    if (allRadioButton3) {
      allRadioButton3.checked = true;
    }
    setSelectedFilter3("All");
  }, [selectedFilter2]);

  const handleServerClick = (value) => {
    const serverName = value[2];
    setSelectedServer(serverName);
    const existingTabId = localStorage.getItem('serverTabId');
    // console.log("EXISTINGTAB: ", existingTabId);
    if (!existingTabId) {
      // Open a new tab and store its reference
      const newTab = window.open(`/server-specifications?servername=${encodeURIComponent(serverName)}`, '_blank');
      if (newTab) {
        const newTabId = `serverTab-${Date.now()}`;
        newTab.name = newTabId;
        localStorage.setItem('serverTabId', newTabId);
        // console.log('NEWTAB: ', newTab);
      }
    } else {
      // Send a message to the existing tab to update its content
      const existingTabWindow = window.open('', existingTabId);
      // console.log("EXISTINGTABWINDOW: ", existingTabWindow);
      if (existingTabWindow && !existingTabWindow.closed) {
        existingTabWindow.postMessage({ serverName }, '*');
      } else {
        // If the existing tab is closed, open a new one
        const newTab = window.open(`/server-specifications?servername=${encodeURIComponent(serverName)}`, '_blank');
        if (newTab) {
          const newTabId = `serverTab-${Date.now()}`;
          newTab.name = newTabId;
          localStorage.setItem('serverTabId', newTabId);
        }
      }
    }
  };

  function indexOf2d(array2d, itemtofind) {
    console.log(
      [].concat.apply([], [].concat.apply([], array2d)).indexOf(itemtofind) !==
        -1
    );
    return (
      [].concat.apply([], [].concat.apply([], array2d)).indexOf(itemtofind) !==
      -1
    );
  }
  const handleDelete = async (value) => {
    try {
      const response = await fetchWithTimeout(`${process.env.REACT_APP_BACKEND_URI}/delete`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify({
          value: value,
          requested_by: graphData.displayName,
          graph_access_token: token,
        }),
      });
      const data = await response.json();
      if (data?.message.includes("Request for delete relation")) {
        toast.error(data.message, {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else if (data?.message.includes("Exception: No Owner")) {
        toast.error("Restricted: Application has not CI owner assigned", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        setDeletedRows([...deletedRows, value]);
        console.log(deletedRows);
        props.setshowCommit(true);
        if (response["status"] == 200) {
          toast.success("Delete request is raised", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          toast.info("You can confirm your changes", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        } else {
          toast.error("Delete request is failed", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      }
    } catch (error) {
      if (error.message === "Timeout") {
        toast.error("Request Timed Out", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        toast.error("Server Error", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    }
  };
  const makeRed = (e) => {
    const row = document.getElementById(e);
    row.style.background = "rgba(233, 178, 183, 1)";
  };
  // useLayoutEffect(() => {
  //   document.getElementById('filter').style.height = heightRef.current?.offsetHeight;
  // }, [heightRef.current?.offsetHeight])
  const updateButtonPosition = () => {
    const tableContainer = document.getElementById("data-table");
    if (tableContainer) {
      var newTableRightBoundary =
        tableContainer.getBoundingClientRect().left +
        tableContainer.offsetWidth;
      var newTableBottomBoundary =
        tableContainer.getBoundingClientRect().bottom +
        tableContainer.offsetHeight;
      // if(document.documentElement.scrollWidth < window.innerWidth){
      //   // newTableRightBoundary+=(window.innerWidth-document.documentElement.scrollWidth);
      //  var a= window.innerWidth-document.documentElement.scrollWidth
      //   newTableRightBoundary-=a;
      //   console.log(typeof(a))
      //   console.log(typeof(newTableRightBoundary))
      // }
      setTableRightBoundary(newTableRightBoundary);
      setTableBottomBoundary(newTableBottomBoundary);
    }
  };
  useLayoutEffect(() => {
    updateButtonPosition();
  }, [table1]);
  useLayoutEffect(() => {
    // Initial update
    // updateButtonPosition();
    // getDimensions()
    // setTimeout(() => {
    //   getToken()
    // }, 2000);
    // getToken()
    const handleResize = () => {
      // setWindowWidth(window.innerWidth);
      // getDimensions()
      updateButtonPosition();
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
   localStorage.removeItem('serverTabId');
  }, []);

  useEffect(() => {
    const tbody = document.querySelector("tbody");
    const downloadButton = document.getElementById("download");
    if (tbody?.scrollHeight > tbody?.clientHeight) {
      if (downloadButton) downloadButton.style.width = "6vw";
    } else {
      if (downloadButton) downloadButton.style.width = "4vw";
    }
  }, [table1, data]);

  return (
    <div>
      <div
      className="extract-button"
        style={{
          margin: "auto",
          // marginTop: "70px"
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <button
          style={{
            border: "none",
            borderRadius: "5px",
            padding: "6px",
            backgroundColor: "rgb(66, 176, 213)",
            color: "white",
            marginBottom: "5px",
          }}
          onClick={handleDownloadClick}
        >
          Extract
          <IoMdDownload
            size={20}
            style={{
              marginLeft: "5px",
            }}
          />
        </button>
      </div>
      <div
        ref={heightRef}
        className="table-main-container"
        style={{
          margin: "auto",
          // marginTop: "70px",
          borderBottom: "2px solid #87CEEB",
          borderLeft: "2px solid #87CEEB",
          maxWidth: "fit-content",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            borderRight: "2px solid #87CEEB",
            borderTop: "2px solid #87CEEB",
            fontSize: "16px",
          }}
        >
          <div style={{ alignSelf: "flex-start", margin: "5px" }}>
            Application ID: {props.applicationId}
          </div>
          <div style={{ alignSelf: "flex-center", margin: "5px" }}>
            {applicationManagerDisplay
              ? `Application Manager: ${props.applicationOwner}`
              : ""}
          </div>
          <div style={{ alignSelf: "flex-end", margin: "5px" }}>
            No. of certified servers :{" "}
            {
              table1?.filter((row1) => {
                // Assuming row1 is an array and data is a 2D array
                const elementAtIndex2Table1 = row1[2];

                // Apply the extra filters
                const filter1Condition =
                  selectedFilter1 === "All" ||
                  row1[props.FilterIndex] === selectedFilter1;
                const filter2Condition =
                  selectedFilter2 === "All" ||
                  row1[props.FilterIndex2] === selectedFilter2;
                const filter3Condition =
                  selectedFilter3 === "All" || row1[0] === selectedFilter3;

                // Return true if all conditions are met
                return (
                  filter1Condition &&
                  filter2Condition &&
                  filter3Condition &&
                  !data.some((rowData) => rowData[2] === elementAtIndex2Table1)
                );
              }).length
            }
            {/* {rowCount} */}
          </div>
        </div>
        <div
          id="filter"
          style={{
            float: "left",
            border: "2px solid #87CEEB",
            borderBottom: "none",
            // backgroundColor:"red",
            borderLeft: "none",
            width: "175px",
            // height:heightRef.current?.offsetHeight,
            // minHeight: "200px",
            // maxHeight: "49vh",
            paddingTop: "7px",
            borderRight: "none",
            overflow: "clip",
            display: "flex",
            flexDirection: "column",
            fontSize: "12px",
          }}
        >
          <div style={{ borderBottom: "2px solid #87CEEB", flex: "0.1" }}>
            <label
              htmlFor="ciTypeFilter"
              style={{ margin: "4px", fontSize: "17px" }}
            >
              Filter By Server Type:
            </label>
          </div>
          <div
            className="filters"
            style={{
              padding: "7px",
              overflowY: "auto",
              flex: "0.3",
              marginBottom: "2px",
            }}
          >
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                value="All"
                name="filter"
                id="All1"
                onChange={() => setSelectedFilter1("All")}
                defaultChecked
              />
              <label
                className="form-check-label"
                htmlFor="All1"
                style={{ fontSize: "16px" }}
              >
                All
              </label>
            </div>
            {uniqueValues
              .filter((value) => value.trim() !== "")
              .map((value, key) => {
                return (
                  <div className="form-check" key={key}>
                    <input
                      className="form-check-input"
                      type="radio"
                      value={value}
                      id={value}
                      name="filter"
                      onChange={() => setSelectedFilter1(value)}
                    />
                    <label
                      className="form-check-label"
                      htmlFor={value}
                      style={{ fontSize: "16px" }}
                    >
                      {value}
                    </label>
                  </div>
                );
              })}
          </div>
          <div style={{ borderBottom: "2px solid #87CEEB", flex: "0.1" }}>
            <label
              htmlFor="ciTypeFilter"
              style={{ margin: "4px", fontSize: "17px" }}
            >
              Filter By Server Role:
            </label>
          </div>
          <div
            className="filters"
            style={{
              padding: "7px",
              overflowY: "auto",
              flex: uniqueValues2?.length > 1 ? "0.3" : "0.1",
            }}
          >
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                value="All"
                name="filter2"
                id="All2"
                onChange={() => setSelectedFilter2("All")}
              />
              <label
                className="form-check-label"
                htmlFor="All2"
                style={{ fontSize: "16px" }}
              >
                All
              </label>
            </div>
            {[...new Set(uniqueValues2)]?.map((value, key) => {
              return (
                <div className="form-check" key={key}>
                  <input
                    className="form-check-input"
                    type="radio"
                    value={value}
                    id={value}
                    name="filter2"
                    onChange={() => setSelectedFilter2(value)}
                  />
                  <label
                    className="form-check-label"
                    htmlFor={value}
                    style={{ fontSize: "16px" }}
                  >
                    {value}
                  </label>
                </div>
              );
            })}
          </div>
          <div style={{ borderBottom: "2px solid #87CEEB", flex: "0.1" }}>
            <label
              htmlFor="ciTypeFilter"
              style={{ margin: "4px", fontSize: "17px" }}
            >
              Filter By App Name:
            </label>
          </div>
          <div
            className="filters"
            style={{ padding: "7px", overflowY: "auto", flex: "0.3" }}
          >
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                value="All"
                name="filter3"
                id="All3"
                onChange={() => setSelectedFilter3("All")}
              />
              <label
                className="form-check-label"
                htmlFor="All2"
                style={{ fontSize: "16px" }}
              >
                All
              </label>
            </div>
            {[...new Set(uniqueValues3)]?.map((value, key) => {
              return (
                <div className="form-check" key={key}>
                  <input
                    className="form-check-input"
                    type="radio"
                    value={value}
                    id={value}
                    name="filter3"
                    onChange={() => setSelectedFilter3(value)}
                  />
                  <label
                    className="form-check-label"
                    htmlFor={value}
                    style={{ fontSize: "16px" }}
                  >
                    {value}
                  </label>
                </div>
              );
            })}
          </div>
        </div>
        <div
          id="table"
          className="table-container"
          ref={heightRef}
          // style={{
          //   marginLeft: "20px",
          //   overflowY: "auto",
          //   tableLayout: "fixed",
          //   borderCollapse: "collapse",
          //   minHeight: "200px",
          //   maxHeight: "350px",
          //   maxWidth: "fit-content",
          //   // display:"table-row-group"
          // }}
        >
          <table id="data-table" className="custom-table">
            <thead>
              <tr>
                {[props.Header1, props.Header2, props.Header3, props.Header4, props.Header5, props.Header6, props.Header7, props.Header8].map((header, index) => (
                  <th key={index} style={{ border: "2px solid #87CEEB" }}>
                    <div style={{ display: "flex", justifyContent: "space-around", alignItems: "center" }}>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        {header}
                        <div style={{ display: "flex", flexDirection: "column", marginLeft: "10px" }}>
                          {sortState1.columnIndex === index && sortState1.order === "asc"  ? (
                            <KeyboardArrowDownIcon
                              style={{
                                cursor: "pointer",
                                fontSize: "24px",
                                fontWeight: "bold", 
                              }}
                              onClick={() => handleSort(index, "desc")}
                            />
                          ) : ( sortState1.columnIndex !== index && 
                            <KeyboardArrowUpIcon
                              style={{
                                cursor: "pointer",
                                fontSize: "16px",
                                fontWeight: "bold", 
                              }}
                              onClick={() => handleSort(index, "asc")}
                            />
                          )}
                          {sortState1.columnIndex === index && sortState1.order === "desc" ? (
                            <KeyboardArrowUpIcon
                              style={{
                                cursor: "pointer",
                                fontSize: "24px",
                                fontWeight: "bold", 
                              }}
                              onClick={() => handleSort(index, "asc")}
                            />
                          ) : ( sortState1.columnIndex !== index &&
                            <KeyboardArrowDownIcon
                              style={{
                                cursor: "pointer",
                                fontSize: "16px", 
                                fontWeight: "bold",
                              }}
                              onClick={() => handleSort(index, "desc")}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </th>
                  )
                )}
                <th
                  id="download"
                  style={{ border: "2px solid #87CEEB", width: "6vw" }}
                >
                  {" "}
                </th>
              </tr>
            </thead>
            <tbody ref={tableBodyRef}>
              {data
                .filter((innerList) => innerList[5] === "create")
                .map((value, key) => {
                  return (
                    <tr
                      key={key}
                      id={key}
                      style={{ background: "rgb(178, 233, 205)" }}
                    >
                      <td style={{ border: "2px solid #87CEEB" }}>
                        {value[0]}
                        <span
                          style={{
                            marginLeft: "10px",
                            display: "inline-block",
                            position: "relative",
                            top: "6px",
                          }}
                          data-tooltip-id="create-info"
                          data-tooltip-content={
                            "Create requested by " +
                            value[7] +
                            " at " +
                            value[6] +
                            " GMT"
                          }
                          data-tooltip-place="bottom-start"
                        >
                          <Icon name="info-circle" size="24" />
                        </span>
                        <Tooltip id="create-info" />
                      </td>
                      <td style={{ border: "2px solid #87CEEB" }}>
                        {value[1]}
                      </td>
                      <td style={{ border: "2px solid #87CEEB" }}>
                        {value[2]}
                        <RxQuestionMarkCircled
                          size={20}
                          style={{
                            marginLeft: "2px",
                            verticalAlign: "text-bottom",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            handleServerClick(value);
                          }}
                        />
                      </td>
                      <td style={{ border: "2px solid #87CEEB" }}>
                        {value[3]}
                      </td>
                      <td style={{ border: "2px solid #87CEEB" }}>
                        {value[4]}
                      </td>
                      <td style={{ border: "2px solid #87CEEB" }}>
                        {value[8]}
                      </td>
                      <td style={{ border: "2px solid #87CEEB" }}>
                        {value[9]}
                      </td>
                      <td style={{ border: "2px solid #87CEEB" }}>
                        {value[10]}
                      </td>
                      <td
                        style={{
                          border: "2px solid #87CEEB",
                          textAlign: "center",
                          // width: "5vw",
                        }}
                      >
                        {/* <DeleteConfirmation onDelete={() => {handleDelete(value);
                        // makeRed(key);
                        } } value={value}/> */}
                      </td>
                    </tr>
                  );
                })}
              {table1?.map((value, key) => {
                return (
                  <tr
                    key={key}
                    id={key}
                    style={{
                      background: data.some(
                        (subArray) =>
                          subArray.includes(value[0]) &&
                          subArray.includes(value[2]) &&
                          subArray[5] === "delete"
                      )
                        ? "rgba(233, 178, 183, 1)"
                        : value[5] === "Decommissioned"
                        ? "rgba(211, 211, 211, 1)"
                        : "none",
                      // : "none",
                      textDecoration: data.some(
                        (subArray) =>
                          subArray.includes(value[0]) &&
                          subArray.includes(value[2]) &&
                          subArray[5] === "delete"
                      )
                        ? "line-through"
                        : "none",
                      // opacity: value[5] === "Decommissioned" ? "0.5" : "1",
                    }}
                  >
                    <td style={{ border: "2px solid #87CEEB" }}>
                      {value[0]}
                      <span
                        style={{
                          marginLeft: "10px",
                          display: data.some(
                            (subArray) =>
                              subArray.includes(value[0]) &&
                              subArray.includes(value[2])
                          )
                            ? "inline-block"
                            : "none",
                          position: "relative",
                          top: "6px",
                        }}
                        data-tooltip-id="delete-info"
                        data-tooltip-content={data
                          .filter(
                            (innerList) =>
                              innerList.includes(value[0]) &&
                              innerList.includes(value[2]) &&
                              innerList[5] === "delete"
                          )
                          .map((item, key) => {
                            return (
                              "Delete requested by " +
                              item[7] +
                              " at " +
                              item[6] +
                              " GMT"
                            );
                          })}
                        data-tooltip-place="bottom-start"
                      >
                        <Icon style={{}} name="info-circle" size="24" />
                      </span>
                      <Tooltip id="delete-info" />
                    </td>
                    <td style={{ border: "2px solid #87CEEB" }}>{value[1]}</td>
                    <td style={{ border: "2px solid #87CEEB" }}>
                      {value[2]}
                      <RxQuestionMarkCircled
                        size={20}
                        style={{
                          marginLeft: "2px",
                          verticalAlign: "text-bottom",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          handleServerClick(value);
                        }}
                      />
                    </td>

                    <td style={{ border: "2px solid #87CEEB" }}>{value[3]}</td>
                    <td style={{ border: "2px solid #87CEEB" }}>{value[4]}</td>
                    <td style={{ border: "2px solid #87CEEB" }}>{value[5]}</td>
                    <td style={{ border: "2px solid #87CEEB" }}>{value[6]}</td>
                    <td style={{ border: "2px solid #87CEEB" }}>{value[7]}</td>
                    <td
                      style={{
                        border: "2px solid #87CEEB",
                        textAlign: "center",
                        // width: "125px",
                      }}
                    >
                      <DeleteConfirmation
                        onDelete={() => {
                          handleDelete(value);
                          // makeRed(key);
                        }}
                        value={value}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
